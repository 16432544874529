import React from "react";
import Table from "./Table";
import { Grid } from "@mui/material";

export default function TableUserDocuments(props) {

  const columns = React.useMemo(
    () => [
      {
        Header: "Site",
        accessor: "site.name",
        width: 1000,
        minWidth: 100,
      },
     
      {
        Header: "Documents",
        accessor: "Documents",
        width: 1000,
        minWidth: 80,
      },
    ],
    []
  );

  const data = React.useMemo(() => props.details.details, [props]);
  const myArray = [data];
// console.log(myArray)
// console.log(data)

  return (
    <>
    {/* {console.log(myArray)}
    {console.log(data2)} */}
     <Grid container spacing={2}>
                <Grid item xs={0} sm={1} />
                <Grid container id="app-table" item xs={12} sm={10}>
      <Table columns={columns} data={data} />
      </Grid></Grid>
    </>
  );
}
