import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isSuccess: false,
  successMessage: "",
  isError: false,
  errorMessage: "",
};

export const notificationsSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    hideSuccess: (state, action) => {
      state.isSuccess = false;
      state.successMessage = "";
    },
    showSuccess: (state, action) => {
      state.isSuccess = true;
      state.successMessage = action.payload;
    },
    hideError: (state, action) => {
      state.isError = false;
      state.errorMessage = "";
    },
    showError: (state, action) => {
      state.isError = true;
      state.errorMessage = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { hideSuccess, showSuccess, hideError, showError } = notificationsSlice.actions;
export const getIsSuccess = (state) => state.notifications.isSuccess;
export const getSuccessMessage = (state) => state.notifications.successMessage;
export const getIsError = (state) => state.notifications.isError;
export const getErrorMessage = (state) => state.notifications.errorMessage;

export default notificationsSlice.reducer;
