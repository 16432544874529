//React-Table
import {
  useGlobalFilter,
  usePagination,
  useTable,
  useSortBy,
} from "react-table";
//Material UI
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import React from "react";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import InputAdornment from "@mui/material/InputAdornment";
import ButtonAddUser from "./ButtonAddUser";

import ButtonGroups from "./ButtonGroups";

import theme from "../../../../Globals/styling/theme";


import ButtonEditUser from "./ButtonEditUser";


export const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <>
      <span>
        <TextField
          id="outlined-basic"

          variant="outlined"
          size="small"
          className="inputRounded"
          value={filter || ""}
          onChange={(e) => setFilter(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchRoundedIcon  style={{ fill: '#fff' }} />
              </InputAdornment>
            ),
          }}
        />
      </span>
    </>
  );
};

const Table = ({ columns, data }) => {


  const handleEntriesChange = (event) => {
    setPageSize(event.target.value);
  };

  const headerColour = (header) => {
    switch (header) {
      case "DATE":
        return theme.tableClientDashboardBarStyle.root.background_default;
      default:
        return theme.tableClientDashboardBarStyle.root.background_default;
    }
  };

  const switchColumn = (cell) => {
    switch (cell.column.Header) {
      case "#":
        return parseInt(cell.row.id) + 1;

      case "Group/s":
        return <ButtonGroups user={cell.row.original}></ButtonGroups>;

      case "Edit":
        return <ButtonEditUser user={cell.row.original}>  </ButtonEditUser>;

      default:
        return cell.render("Cell");
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    state,
    setGlobalFilter,
  } = useTable(
    { columns, data, initialState: { pageSize: 10 },
    autoResetPage: false,
    autoResetGlobalFilter: false,
    autoResetFilters: false, },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { globalFilter } = state;

  return (
    <>
      <Grid container columns={{ xs: 6, sm: 8, md: 10 }} alignItems="baseline">
        <Grid item xs={3} sm={4} md={5} align="left">
        <Select style={{backgroundColor:"#fff", color:"#13cbc5"}} labelId="entries-label" id="entries-label" value={pageSize} onChange={handleEntriesChange} size="small">
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>

        </Grid>
        {/* <FilterButtonGroup/> */}

        <Grid item xs={3} sm={4} md={5} align="right">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        </Grid>

        {/* new row */}
        <Grid item xs={6} sm={8} md={10} mt={1} mb={1}>
          <Paper style={theme.tableStyle.tableBackground} elevation={2}>
            <table
              {...getTableProps()}
              style={theme.tableStyle.primaryTable_table}
            >
              <thead stlye={theme.tableStyle.tableHead}>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps({
                            style: {
                              fontSize:
                                theme.tableStyle.primaryTable_th.fontSize,
                              height: theme.tableStyle.primaryTable_th.height,
                              textAlign:
                                theme.tableStyle.primaryTable_th.textAlign,
                              minWidth: column.minWidth,
                              width: column.width,
                              fontFamily: theme.typography.fontFamily,
                              background: headerColour(column.Header),
                              color:
                                theme.tableClientDashboardBarStyle.root.color,
                            },
                          })
                        )}
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            style={theme.tableStyle.primaryTable_td}
                            {...cell.getCellProps()}
                          >
                            {switchColumn(cell)}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Paper>
        </Grid>
        <Grid item xs={3} sm={4} md={5} align="left" sx={{ mb: 5 }}>
          <ButtonAddUser />
          {data.length > pageSize ? (
 <ButtonGroup aria-label="button group">
 <Button variant="outlined"  sx={{color:'#13cbc5', borderColor:'#13cbc5',  borderRadius: '25px 0px 0px 25px', "&:disabled": {color:"#4C4C4D", borderColor:"#13cbc5"}}} onClick={() => previousPage()} disabled={!canPreviousPage}>
   Previous
 </Button>
 <Button variant="contained"  style={theme.mainFilterButton.root} >{pageIndex + 1}</Button>
 <Button variant="outlined"  sx={{color:'#13cbc5', borderColor:'#13cbc5', borderRadius: '0px 25px 25px 0px', "&:disabled": {color:"#4C4C4D", borderColor:"#13cbc5"}}} onClick={() => nextPage()} disabled={!canNextPage}>
   Next
 </Button>
</ButtonGroup>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Table;
