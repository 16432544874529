//React
import React from "react";
//Material UI
import Backdrop from "@mui/material/Backdrop";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {Button, Grid, Box} from '@mui/material';
import {useEffect, useState} from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DialogContentText from '@mui/material/DialogContentText';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { showSuccess, showError } from "../../Features/Notifications/notificationsSlice";

//Components
//Redux
import AttractionsOutlinedIcon from '@mui/icons-material/AttractionsOutlined';
import { useGetAppTypeCatagoriesQuery, useGetQuestionTemplatesMutation, 
  useNewTicketMutation } from "../../Features/api/apiSlice";
//Redux
import { useDispatch } from 'react-redux'


import ClearIcon from '@mui/icons-material/Clear';
import theme from "./theme";

export default function DEMButton (props) {

  const dispatch = useDispatch()

  const speedialActions = [
    // { icon: <ContactMailIcon />, name: "Get In Touch" },
    { icon: <QuestionAnswerIcon />, name: "Give Feedback" },
    // { icon: <LinkedInIcon />, name: "Follow Us" },
    // { icon: <WhatsAppIcon />, name: "WhatsApp Us" },
  ];

  const [open, setOpen] = React.useState(false);
  const [message, setmessage] = React.useState('Description');
  const [name, setname] = React.useState('Name');
  const [email, setemail] = React.useState('Email');
  const [openContact, setOpenContact] = React.useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [type, setType] = useState('');
  const [openDEM, setOpenDEM] = React.useState(false);

  const app = "userport";
  const url = window.location.href;
  let answers = []

  const TypeCatagoriesQueryParams = {
    get_request_type: 'app_type_catagories',
    app_name: 'userport',
  };
  const { data: type_catagories, isSuccess: isGetTypeCatagoriesSuccess, isError, error} = useGetAppTypeCatagoriesQuery({ queryParams: TypeCatagoriesQueryParams });
  const [ getQuestionsTemplate, { data: questions_template, isSuccess: isGetQuestionsTemplateSuccess }] = useGetQuestionTemplatesMutation();
  const [ newTicket, {data: new_ticket_data, isSuccess: isNewTicketSuccess, error: new_ticket_error, isError: isIsNewTicketError} ] = useNewTicketMutation();

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if(type !== ''){
      const QuestionsTemplateQueryParams = {
        get_request_type: 'questions_template',
        app: app,
        ticket_type: type,
      };
      getQuestionsTemplate({ queryParams: QuestionsTemplateQueryParams })
    }
  },[type]); 

  useEffect(() => {
    if(isGetQuestionsTemplateSuccess){
      let questions = questions_template.data
      questions.forEach((item) => {
        answers.push({'question': item.question, 'answer':''})
      })
    }
  },[questions_template]); 

  const handleOpenM = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (isNewTicketSuccess) {
      dispatch(showSuccess("Successfully Added Ticket"))
    } else if (isIsNewTicketError) {
      dispatch(showError("Could not add Ticket"))
    }
  }, [isNewTicketSuccess, isIsNewTicketError]);

  const handleCloseModal = () => setOpen(false);

  const handleOpenModal = (name) => {
    switch (name) { 
     
      case "Give Feedback":
        setOpenDEM(true);
        return

      default:
        return
    }
  };
  
  const handleOnSubmit = (event) => {
    event.preventDefault();
    const body = { 
      'ticket_app': app,
      'ticket_type': type,
      'ticket_questions': answers,
      'ticket_url': url,
      'post_type': 'new_ticket',
    };
    newTicket({ body: body })
    setOpenDEM(false);
    setType('');
  };

  const handleClientInputChange = (event, index) => {
    answers[index]['answer'] = event.target.value;
  };

  const handleCloseDEM = () => {
    setOpenDEM(false);
    setType('')
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleCloseModal}>
        <Box>
          <Backdrop open={open} />
          <SpeedDial
          FabProps={{ size: "medium", style: { backgroundColor: '#6C6B6D', color:"#fff" } }}
            ariaLabel="Mobile SpeedDial"
            style={theme.bottomNavStyle.floatingActionStyle}
            icon={<AttractionsOutlinedIcon />}
            onClick={handleOpenM}
            open={open}
          >
            {speedialActions.map((action) => (
              <SpeedDialAction
                // style={theme.bottomNavStyle.toolTipStyle}
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={() =>handleOpenModal(action.name)}
                tooltipOpen={true}
              ></SpeedDialAction>
              ))}
          </SpeedDial>
        </Box>
      </ClickAwayListener>

      {(isGetTypeCatagoriesSuccess) ? (
        <Dialog 
          open={openDEM} 
          onClose={handleCloseDEM} 
          BackdropProps={{ invisible: true }}
       PaperProps={{
                 
        style: {
          backgroundColor: "#000",
          boxShadow: "none",
          borderWidth: 2,
          borderRadius: 10,
          borderColor: "#fff",
          borderStyle: "solid",
          backgroundColor: "#101010",
          color: "#fff"

        },
      }}      
          style={theme.modalStyle.root}
        >
          <DialogTitle >
          <ClearIcon sx={{top:0, right:0}} onClick={handleCloseDEM}/>
          <Typography variant="h6" style={{color:"#fff"}}>Give Feedback</Typography>
          
            </DialogTitle>
          <Grid component="form" onSubmit={(e) => handleOnSubmit(e)} >
            <DialogContent >
              <DialogContentText style={{color:"#fff"}} variant="body3">
                Fill out the below form to give feedback for the application.
              </DialogContentText>
              <br />
              <Typography style={{color:"#fff"}} sx={{mb: 1}} variant="h6">
                  Select Feedback Form
              </Typography>

              <FormControl fullWidth sx={{mt: 0}}
              className="inputRounded">
                <Select
                labelId="type-select-label"
                id="type-select"
                value={type}
                onChange={handleTypeChange}
                >
                  {type_catagories.data.map((item) => (
                      <MenuItem key={item.description} value={item.description}>{item.description}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {(isGetQuestionsTemplateSuccess && type != '') ? 
              <>
                {questions_template.data.map((question, index) => (
                  <div key={question.id}>
                  <Typography style={{color:"#fff"}} sx={{mt: 4}} variant="h6">
                  {question.question}
                  </Typography>
                  <TextField 
                   className="inputRounded"
                    required
                    fullWidth
                    multiline
                    maxRows={10}
                    onChange={(event) => handleClientInputChange(event, index)}
                  />
                </div>
                ))}
              </> 
              : 
              <></>}
            </DialogContent>
            <DialogActions>
              <Button style={ theme.mainButton.root} variant="contained" type='submit' >Submit</Button>
            </DialogActions>
          </Grid>
        </Dialog>
      ) 
      : 
      <></>
      }
    </>
  );
};
 
function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}