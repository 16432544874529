import React from "react";

import {
FooterName,
Nav,
FooterLink,
} from "./FooterStyles";

import creng from "../../Globals/components/Images/CRENG_LOGO_DARK.png"

 function Footer(props) {

return (
    <div style={{backgroundColor: '#000'}}>

	<Nav>
        <FooterLink src={creng} width="150px"/>
        <FooterName>Powered by Cauchy Consult 2023  © v3.0.0</FooterName>

	</Nav>

    </div>
);
};
export default Footer;
