import * as React from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import { useNavigate } from "react-router-dom";
import { useGetSitesAssignedToUserQuery } from "../../../../api/apiSlice";
import TableUserDocuments from "./TableUserDoc";
//     getModalOpen,  setModalOpen, 
//   } from "./siteSlice";
export default function LandingTableUserProjectDocuments() {
  let navigate = useNavigate();
  const
  {
  data: userData,
  isLoading: userLoading,
  isSuccess: userSuccess,
  isError: userError,
  error: userMessage,
} = useGetSitesAssignedToUserQuery();

if(userSuccess){
  return (
    <>
    {console.log(userData.details)}

   <TableUserDocuments details={userData}/>

    </>
  );
}
else return (<></>);
}
