import React from "react";
import Table from "./Table";
import { Grid } from "@mui/material";

export default function TableUsers(props) {

  const columns = React.useMemo(
    () => [
      {
        Header: "Email",
        accessor: "email",
        width: 500,
        minWidth: 100,
      },
      {
        Header: "First_Name",
        accessor:"first_name",
        width: 500,
        minWidth: 80,
      },
      {
        Header: "Last Name",
        accessor: "last_name",
        width: 500,
        minWidth: 80,
      },
      {
        Header: "Cell Number",
        accessor: "cell_number",
        width: 500,
        minWidth: 80,
      },

      {
        Header: "Group/s",
        accessor: "groups",
        width: 500,
        minWidth: 20,
      },
      {
        Header: "Edit",
        width: 500,
        minWidth: 20,
      },
    ],
    []
  );



  return (
    <>

     <Grid container spacing={2}>
                <Grid item xs={0} sm={1} />
                <Grid container id="app-table" item xs={12} sm={10}>
      <Table columns={columns} data={props.user_data} />
      </Grid></Grid>
    </>
  );
}
