import { blue, green, grey, orange, pink, purple, red, black } from "@mui/material/colors";

import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: blue[500],
      contrastText: "#fff",
    },

    information: {
      main: blue[500],
    },

    secondary: {
      main: red[500],
    },

    error: {
      main: red[500],
    },

    warning: {
      main: orange[500],
      contrastText: "#fff",
    },

    success: {
      main: green[500],
      contrastText: "#fff",
    },

    cancel_button: {
      main: grey[500],
      contrastText: "#fff",
    },

    filter_modal_form_control: {
      main: grey[600],
    },

    update_modal_form_control: {
      main: grey[600],
    },

    button_page_link: {
      main: { textDecorationLine: "none", color: "inherit" },
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },

  logInStyle: {
    appImage: {
      width: "100%",
      height: "280px",
      objectFit: "contain",
    },
    card: {
      minHeight: "100vh",
      backgroundColor: blue[200],
    },
  },

  // https://stackoverflow.com/questions/69751510/how-to-have-different-color-for-alternate-row-for-mui-v4-table
  tableStyle: {
    primaryTable_th: {
      fontSize: 13,
      height: 40,
      textAlign: "center",
      color: "#ffff",
      width: 1000,
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      backgroundColor: blue[500],
    },

    tableBackground:{
      backgroundColor: "#4C4C4D",
      //  #464666
      color: "#fff"
    },

    primaryTable_td: {
      fontSize: 13,
      height: 40,
      textAlign: "center",
    },
    secondaryTable_td: {
      fontSize: 13,
      height: 40,
      textAlign: "left",
    },

    primaryTable_table: {
      border: "none",
      borderRadius: "4px",
      borderCollapse: "collapse",
      // Overflow hidden solved a major table corner rounding issue:
      overflow: "hidden",
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },

    secondaryTable_table: {
      border: "none",
      borderRadius: "4px",
      borderCollapse: "collapse",
      // Overflow hidden solved a major table corner rounding issue:
      overflow: "hidden",
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  },
  navBarStyle: {
    menu_item_icon: {
      backgroundColor: "transparent",
    },
  },

  bottomNavStyle: {
    floatingActionStyle: {
      margin: "0px",
      top: "auto",
      right: "20px",
      bottom: "20px",
      left: "auto",
      position: "fixed",
    },
    toolTipStyle: {
      maxWidth: "none",
      whiteSpace: "nowrap",
    },
    toolTipStyle: {
      maxWidth: "620px",
    },
  },

  bottomQRNavStyle: {
    floatingActionStyle: {
      margin: "0px",
      top: "auto",
      right: "80px",
      bottom: "20px",
      left: "auto",
      position: "fixed",
    },
    toolTipStyle: {
      maxWidth: "none",
      whiteSpace: "nowrap",
    },
    toolTipStyle:{
      maxWidth : '620px',
    }
  },

  headerStyle: {
    fontFamily: ["helvetica", "serif"].join(","),
    color: "#424242",
    fontSize: 24,
    textAlign: "center",
    textShadow: "6px 6px 6px #c9c6c6",
    display: "block",
    fontWeight: "bolder",
    paddingBottom: "5px",
  },
  tableHeadingStyle: {
    fontFamily: ["helvetica", "serif"].join(","),
    color: "#424242",
    fontSize: 20,
    textAlign: "center",
    textShadow: "6px 6px 6px #c9c6c6",
    display: "block",
    fontWeight: "bolder",
    paddingBottom: "3px",
  },
  tableDeveloperDashboardBarStyle: {
    root: {
      background_default: 'linear-gradient(45deg, #2196f3 30%, #129bf4 90%)',
      background_date: 'linear-gradient(45deg, #129bf4 30%, #009ff5 90%)',
      background_detail: 'linear-gradient(45deg, #009ff5 30%, #00a4f5 90%)',
      background_app: 'linear-gradient(45deg, #00a4f5 30%, #00a8f5 90%)',
      background_priority: 'linear-gradient(45deg, #00a8f5 30%, #00adf5 90%)',
      background_status: 'linear-gradient(45deg, #00adf5 30%, #00b1f5 90%)',
      background_lead_dev: 'linear-gradient(45deg, #00b1f5 30%, #00b5f4 90%)',
      background_release: 'linear-gradient(45deg, #00b5f4 30%, #12b9f4 90%)',
      background_type: 'linear-gradient(45deg, #12b9f4 30%, #21bdf3 90%)',
      boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
      color: 'white',
    },
  },
  tableClientDashboardBarStyle: {
    root: {
      background_default: 'linear-gradient(45deg, #6C6B6D 30%, #e2e1e2 90%)',
      background_detail: 'linear-gradient(45deg, #f78470 30%, #f88c6d 90%)',
      background_app: 'linear-gradient(45deg, #f88c6d 30%, #f8946b 90%)',
      background_priority: 'linear-gradient(45deg, #f8946b 30%, #f89c69 90%)',
      background_status: 'linear-gradient(45deg, #f89c69 30%, #f7a469 90%)',
      background_type: 'linear-gradient(45deg, #f7a469 30%, #f6ac69 90%)',
      boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
      color: 'white',
    },
  },
  tableClientQuestionnaireBarStyle: {
    root: {
      background_default: '#6C6B6D',
      background_questions: 'linear-gradient(45deg, #7fe081 30%, #87e289 90%)',
      background_type: 'linear-gradient(45deg, #87e289 30%, #8fe591 90%)',
      background_apps: 'linear-gradient(45deg, #8fe591 30%, #97e799 90%)',
      background_matrix: 'linear-gradient(45deg, #97e799 30%, #9eeaa0 90%)',
      background_actions: 'linear-gradient(45deg, #9eeaa0 30%, #a6eca8 90%)',
      boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
      color: 'black',
    },
  },
  buttonGroupStyle: {
    root: {
      display: 'flex'
    }
  },
  blueButtonStyle: {
    root: {
    fontFamily: ["helvetica", "serif"].join(","),
    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
    color: 'white',
    }
  },
  tableStyles: {
    root: {
      background: '#6C6B6D',
      border: 0,
      borderRadius: 3,
      color: 'white',
    }
  },
  disabledButtonStyle: {
    root: {
    fontFamily: ["helvetica", "serif"].join(","),
    background: 'linear-gradient(45deg, #6e797a 30%, #b5c6c9 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(188, 219, 224, .3)',
    color: 'white',
    }
  },
  warningButtonStyle: {
    root: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      }
    },
  clientFilterButtonStyle: {
    root: {
    fontFamily: ["helvetica", "serif"].join(","),
    background: '#6C6B6D',
    border: 0,
    borderRadius: 3,
    color: '#cb1319',
    contrastText: '#cb1319',
    }
  },
  devFilterButtonStyle: {
    root: {
    fontFamily: ["helvetica", "serif"].join(","),
    background: 'linear-gradient(45deg, #21A9F3 30%, #21BDF3 90%)',
    border: 0,
    borderRadius: 3,
    color: '#fff',
    contrastText: '#fff',
    }
  },
  devFilterModalHeadingStyle: {
    root: {
    fontFamily: ["helvetica", "serif"].join(","),
    background: 'linear-gradient(45deg, #2196F3 30%, #21BDF3 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    }
  },
  devUpdateModalHeadingStyle: {
    root: {
    fontFamily: ["helvetica", "serif"].join(","),
    background: 'linear-gradient(45deg, #2196F3 30%, #21BDF3 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    }
  },
  clientQuestionnaireUpdateModalHeadingStyle: {
    root: {
    fontFamily: ["helvetica", "serif"].join(","),
    background: 'linear-gradient(45deg, #77dd79 30%, #8fe591 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    }
  },
  clientDashboardUpdateModalHeadingStyle: {
    root: {
    fontFamily: ["helvetica", "serif"].join(","),
    background: 'linear-gradient(45deg, #f57c73  30%, #f6ac69 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    }
  },

  modalHeading:{
    root:{
      fontFamily: ["helvetica", "serif"].join(","),
      color: '#fff',
      fontSize: 30,

    }
  },

  cancelButtonStyle: {
    root: {
    fontFamily: ["helvetica", "serif"].join(","),
    background: '#E54c38',
    border: 0,
    borderRadius: 3,
    color: 'white',
    }
  },
  updateButtonStyle: {
    root: {
    fontFamily: ["helvetica", "serif"].join(","),
    background: '#77DD77',
    border: 0,
    borderRadius: 3,
    color: 'white',
    }
  },
  filterCheckboxStyle: {
    root: {
    color: '#21AEF3',
    }
  },
  clientDashboardFilterCheckboxStyle: {
    root: {
    color: '#6c6b6d',
    }
  },
  clientQuestionnaireUpdateCheckboxStyle: {
    root: {
    color: '#cb1319',
    disabledColour: grey[600]
    }
  },
  cardStyle: {
      root: {
        background: '#ebeced',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(185, 214, 237, .3)',
        color: 'white',
    }
  },
  modalStyle: {
    root: {
      backdropFilter: 'blur(2px)',
    }
  },
  modalCardStyle: {
    root: {
      borderRadius: '8px',
      backdropFilter: 'blur(20px)',
      backgroundColor: 'rgba(255,255,255,0.5)',
      boxShadow: '0 1px 12px rgba(0, 0, 0, 0.25)',
      border: '1px solid rgba(255, 255, 255, 0.3)'
    }
  },
  appBarStyle: {
    root: {
      background: 'linear-gradient(45deg, #6C6B6D 30%, #e2e1e2 90%)',
      border: 0,
      borderRadius: 3,
      borderBottom: "4px solid",
      borderBottomColor: "#cb1319",
      
      color: 'white',
    },
  },
  mainHeading: {
    root: {
      fontFamily: ["helvetica", "serif"].join(","),
      fontSize: 30,
      color: "white",
      textAlign: "center",
      'textShadow': "1px 1px 2px #7694b3",
    },
  },
  subHeading: {
    root: {
      fontFamily: ["helvetica", "serif"].join(","),
      fontSize: 20,
      color: "#fff",
      textAlign: "center",
      paddingTop: "10px",
      // 'textShadow': "1px 1px 2px #7694b3",
    },
  },
  stepper: {
    root: {
      "& .MuiStepIcon-active": { color: "red" },
      "& .MuiStepIcon-completed": { color: "green" },
      "& .Mui-disabled .MuiStepIcon-root": { color: "cyan" }
    }
  },
  speedDial:{
    root:{
      backgroundColor: "#e2e1e2",
      color: "#cb1319"
    }
  },
  mainButton:{
    root:{
      background: "#13cbc5",
      border: 0,
      borderRadius: 20,
      color: 'black',
      '&:hover':
      {
        color: '#fff',
        backgroundColor: '#13cbc5',
        cursor: 'pointer'
      },
    }
  },

  mainFilterButton:{
    root:{
      background: '#13cbc5',
      border: 0,
      color: 'white',
    }
  },
 mainButton2:{
    root:{
     borderColor: '#13cbc5',
      borderRadius: 20,
      color: '#13cbc5',

      '&:hover':
      {
        color: '#000',
        backgroundColor: '#13cbc5',
        cursor: 'pointer'
      },
      
    }
  },

  mainButton3:{
    root:{
     borderColor: '#41d16f',
      borderRadius: 20,
      color: '#41d16f',

      '&:hover':
      {
        color: '#fff',
        backgroundColor: '#41d16f',
        cursor: 'pointer'
      },
      
    }
  },

  subButton:{
    root:{
      background: '#6c6b6d',
      border: 0,
      borderRadius: 20,
      color: 'white',
    }
  }

});

export default theme;
