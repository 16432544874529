import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "./apiHelper";
import { concatPathWithParams } from "./apiHelper";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SECRET_API}`,
    prepareHeaders: (headers) => {
      const token = getToken();
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),

  tagTypes: [
    "type_catagories",
    "privacy_policy", 
    "user_details",
    "file_uploads",
    "file_upload_list",
    "user_list",
    "user_details",
    "file_list",
    "file_validation",
    "sites_for_project_assign",
    "assign_user",
    "sites_assigned_to_user",
    "docs_assigned_to_user",
    "subdocs_assigned_to_user",
    "internal_external"
  ],
  endpoints: (builder) => ({    

    getAppTypeCatagories: builder.query({
      query: (request) => concatPathWithParams(`${process.env.REACT_APP_SECRET_DEM_API}/quality_control/questions/`, request.queryParams),
      providesTags: ["type_catagories"],
    }),

    getQuestionTemplates: builder.mutation({
      query: (request) => ({
        url: concatPathWithParams(`${process.env.REACT_APP_SECRET_DEM_API}/quality_control/questions/`, request.queryParams),
        method: "GET",
        body: request.body,
      }),
      providesTags: ["question_templates"],
    }),

    getUser: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_SECRET_API}/auth/user-details/`,
      }),
      providesTags: ["user_details"],
    }),
    getInternalExternal: builder.query({
      query: (request) => ({
        url: `${process.env.REACT_APP_SECRET_API}/auth/user/${request.id}/getInternalExternal/`,
      }),
      providesTags: ["internal_external"],
    }),

    getUserList: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_SECRET_API}/auth/user-list-view/`,
      }),
      providesTags: ["user_list"],
    }),

    getUserDetails: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_SECRET_API}/auth/user-details/`,
      }),
      providesTags: ["user_details"],
    }),

    updateUserDetails: builder.mutation({
      query: (request) => ({
        url: `${process.env.REACT_APP_SECRET_API}/auth/user-details/`,
        method: "PATCH",
        body: request.body,
      }),
      invalidatesTags: ["user_details"],
    }),

    getUploadedFilesForUser: builder.query({
      query: (id) => ({
        url: `${process.env.REACT_APP_SECRET_API}/onboarding/user/${id}/file-upload/`,
       
      }),
      providesTags: ["file_uploads"],
    }),
    
    getUploadedFilesForInternalExternal: builder.query({
      query: (id) => ({
        url: `${process.env.REACT_APP_SECRET_API}/onboarding/user/${id}/file-upload-list/`,
       
      }),
      providesTags: ["file_upload_list"],
    }),

    getFilesForInternalExternalManager: builder.query({
      query: (id) => ({
        url: `${process.env.REACT_APP_SECRET_API}/onboarding/user/${id}/file-upload/list/`,
       
      }),
      providesTags: ["file_list"],
    }),

    getFileForInternalExternalValidation: builder.query({
      query: (id, fileid) => ({
        url: `${process.env.REACT_APP_SECRET_API}/onboarding/user/${id}/file-upload-update/${fileid}/`,
       
      }),
      providesTags: ["file_validation"],
    }),

    uploadFileForInternalExternal: builder.mutation({
      query: (request, id) => ({
        url: `${process.env.REACT_APP_SECRET_API}/onboarding/user/3/file-upload/${request.id}/`,
        method: "POST",
        body: request.body,
      }),
      invalidatesTags: ["file_upload_list","file_uploads"],
    }),

    uploadFileForUser: builder.mutation({
      query: (request, id) => ({
        url: `${process.env.REACT_APP_SECRET_API}/onboarding/user/${request.id}/file-upload/`,
        method: "POST",
        body: request.body,
      }),
      invalidatesTags: ["file_validation","file_upload_list","file_uploads"],
    }),

    updateFileValidation: builder.mutation({
      query: (request) => ({
        url: `${process.env.REACT_APP_SECRET_API}/onboarding/user/${request.id}/file-upload-update/${request.file_id}/`,
        method: "PATCH",
        body: request.body,
      }),
      invalidatesTags: ["file_validation","file_upload_list","file_uploads"],
    }),
    newTicket: builder.mutation({
      query: (request) => ({
        url: `${process.env.REACT_APP_SECRET_DEM_API}/quality_control/tickets/`,
        method: "POST",
        body: request.body,
      }),
    }),


    newUser: builder.mutation({
      query: (request) => ({
        url: `${process.env.REACT_APP_SECRET_API}/auth/create/`,
        method: "POST",
        body: request.body,
      }),
      invalidatesTags: ["user_list"],

    }),

    editUser: builder.mutation({
      query: (request) => ({
        url: `${process.env.REACT_APP_SECRET_API}/auth/create/`,
        method: "PATCH",
        body: request.body,
      }),
      invalidatesTags: ["user_list"],

    }),

      getSitesForProjectAssign: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_SECRET_PAMP_API}/project/management/site/completeDocList/`,
       
      }),
      providesTags: ["sites_for_project_assign"],
    }),

    assignUserToSite: builder.mutation({
      query: (request) => ({
        url: `${process.env.REACT_APP_SECRET_PAMP_API}/project/management/site/${request.id}/assignUser/`,
        method: "POST",
        body: request.body,
      }),
      invalidatesTags: ["assign_user"],
    }),
 

    getSitesAssignedToUser: builder.query({
      query: (request) => ({
        url: `${process.env.REACT_APP_SECRET_PAMP_API}/project/management/user/sitesAssigned/`,

      }),
      providesTags: ["sites_assigned_to_user"],
    }),

    getEmpDocumentsSitesAssignedToUser: builder.query({
      query: (request) => ({
        url: `${process.env.REACT_APP_SECRET_PAMP_API}/project/management/site/${request.site_id}/viewEmployeeDocuments/${request.user_id}`,

      }),
      providesTags: ["docs_assigned_to_user"],
    }),


    getSubDocumentsSitesAssignedToUser: builder.query({
      query: (request) => ({
        url: `${process.env.REACT_APP_SECRET_PAMP_API}/project/management/site/${request.site_id}/viewSubDocuments/${request.user_id}`,

      }),
      providesTags: ["subdocs_assigned_to_user"],
    }),

    editEmpDocumentsSitesAssignedToUser: builder.mutation({
      query: (request) => ({
        url: `${process.env.REACT_APP_SECRET_PAMP_API}/project/management/site/${request.site_id}/viewEmployeeDocuments/edit/${request.file_id}/`,
        method: "PATCH",
        body: request.body,
      }),
      invalidatesTags: ["docs_assigned_to_user"],
    }),


    editSubDocumentsSitesAssignedToUser: builder.mutation({
      query: (request) => ({
        url: `${process.env.REACT_APP_SECRET_PAMP_API}/project/management/site/${request.site_id}/viewSubDocuments/edit/${request.file_id}/`,
        method: "PATCH",
        body: request.body,
      }),
      invalidatesTags: ["subdocs_assigned_to_user"],
    }),

  }),
});


export const {  
  useEditEmpDocumentsSitesAssignedToUserMutation,
useEditSubDocumentsSitesAssignedToUserMutation,
  useGetEmpDocumentsSitesAssignedToUserQuery,
  useGetSubDocumentsSitesAssignedToUserQuery,
  useGetAppTypeCatagoriesQuery,
  useGetPrivacyPolicyQuery,
  useGetUploadedFilesForUserQuery,
  useGetUploadedFilesForInternalExternalQuery,
  useUpdateFileValidationMutation,
  useGetUserQuery,
  useGetFileForInternalExternalValidationQuery,
  useGetFilesForInternalExternalManagerQuery,
  useGetSitesForProjectAssignQuery,
  useGetUserListQuery,
  useGetUserDetailsQuery,
  useGetQuestionTemplatesMutation,
  useUploadFileForUserMutation,
  useUploadFileForInternalExternalMutation,
  useAssignUserToSiteMutation,
  useNewTicketMutation,
  useNewUserMutation,
  useSendEmailMutation,
  useGetInternalExternalQuery,
  useEditUserMutation,
  useUpdateUserDetailsMutation,
useGetSitesAssignedToUserQuery } = apiSlice;
