import React from "react";
import Table from "./Table";
import { Grid } from "@mui/material";

export default function TableUserDocuments(props) {

  const columns = React.useMemo(
    () => [

      {
        Header: "User ID",
        accessor: "id",
        width: 100,
        minWidth: 100,
      },
      {
        Header: "Name",
        accessor: "first_name",
        width: 500,
        minWidth: 100,
      },
      {
        Header: "Last_Name",
        accessor:"last_name",
        width: 500,
        minWidth: 80,
      },
      {
        Header: "Email",
        accessor: "email",
        width: 500,
        minWidth: 80,
      },

      {
        Header: "Documents",
        accessor: "Documents",
        width: 500,
        minWidth: 80,
      },
    ],
    []
  );

  // const data = React.useMemo(() => props.tickets_data, [props]);
  const data = React.useMemo(() => [
    {
        "Name": "First name",
        "Last_Name": "Last Name",
        "Email": "test@email.com",

 
    },])

  return (
    <>
     <Grid container spacing={2}>
                <Grid item xs={0} sm={1} />
                <Grid container id="app-table" item xs={12} sm={10}>
      <Table columns={columns} data={props.user_data} />
      </Grid></Grid>
    </>
  );
}
