import React from "react";
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import ClearIcon from "@mui/icons-material/Clear";
import { Grid, CardActions } from "@mui/material";
import { showSuccess, showError } from "../../../../Notifications/notificationsSlice";

import TextField from '@mui/material/TextField';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoIcon from '@mui/icons-material/Info';

import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import {useGetUserListQuery, useGetUserQuery, useGetUploadedFilesForUserQuery, useGetUploadedFilesForInternalExternalQuery, useUploadFileForInternalExternalMutation} from "../../../../../Features/api/apiSlice"
import theme from "../../../../../Globals/styling/theme"
export default function ButtonDocuments() {

    const dispatch = useDispatch();

    const navigate = useNavigate();
    const [open, setopen] = useState(false);
    const [Open, setOpen] = useState(false);
    const [file_name, setfile_name] = useState("");
    const [file_valid, setfile_valid] = useState("");
    const [file_id, setfile_id] = useState("");
    const [file_details, setfile_details] = useState("");
    const [file_field, setfile_field] = useState("");

    const [openFileUpload, setopenFile] = useState(false);
    
    const [newFileUpload, 
      {
      data: newFileData,
      isLoading: newFileLoading,
      isSuccess: newFileSuccess,
      isError: newFileError,
      error: newFileMessage,
  },
  
  ] = useUploadFileForInternalExternalMutation();


  
  const
    {
    data: userData,
    isLoading: userLoading,
    isSuccess: userSuccess,
    isError: userError,
    error: userMessage,
} = useGetUserQuery();




const

{
data: userFileListData,
isLoading: userFileListLoading,
isSuccess: userFileListSuccess,
isError: userFileListError,
error: userFileListMessage,
} = useGetUploadedFilesForInternalExternalQuery(3);
  
React.useEffect(() => {
  if (newFileSuccess) {
      // console.log(newFileData)
      let message = `${newFileData.success}`;
      dispatch(showSuccess("Succesfully Uploaded"));
      window.location.reload(true);
  }
  if (newFileError) {
      // console.log(newFileMessage)
      let message = `${newFileMessage.data.error}`;
      dispatch(showError("Could not upload file"));
  }
}, [newFileSuccess, newFileError]);


    const handleClick = () => {
      setopenFile(true);

  };
   
  
    const openFile = () => {
      window.open(file_field);
    };

    const openDocumentsList = (item) => {
      setopen(true);
      // console.log(userFileListData.details[0].doc_name)
     
      //  console.log(item.id)
      //  console.log(item.doc_name)
      //  console.log(item.file)
       setfile_name(item.doc_name)
       setfile_id(item.id)
       setfile_field(item.file)
       setfile_valid(item.valid)
      //  setfile_details(item.file_details)
  
    };
    const closeDocumentList = () => {
      setopen(false);
    };
  
    const closeFile = () => {
      setopenFile(false);
    };

    const openInfo = (file_details) => {
      console.log(file_details)
      setfile_details(file_details)
      setOpen(true);
    };
  
    const closeInfo = () => {
      setOpen(false);
    };

    const handleSubmit = (event) => {
      event.preventDefault();
      const body = new FormData(event.currentTarget);
      const request = { body: body, id: file_id };
      newFileUpload(request);
      setopen(false);
    };

    const openFileFor = (id) => {
      // let file_for_entity_type = entity_type.compliance_file_field.find(x => x.id === id).file_field;
      // window.open(file_for_entity_type);
  
    };
    // React.useEffect(() => {
    //   if (userFileListSuccess) {
    //     setfile_name(userFileListData.details.doc_name)
    //     setfile_id(userFileListData.details.id)
    //     setfile_field(userFileListData.details.file)
    //     setfile_valid(userFileListData.details.valid)
    //   }
     
    // }, [userFileListSuccess, userFileListLoading]);

if(userFileListSuccess){
    return (
        <>
            <Button sx={ theme.mainButton.root}  onClick={() => handleClick()}>
                Documents
            </Button>


            <Dialog
       BackdropProps={{ invisible: true }}
       PaperProps={{
                 
        style: {
          backgroundColor: "#000",
          boxShadow: "none",
          borderWidth: 2,
          borderRadius: 10,
          borderColor: "#fff",
          borderStyle: "solid",
          backgroundColor: "#101010",
          color: "#fff"

        },
      }}          
       style={theme.modalStyle.root}
        open={open}
        onClose={closeDocumentList}
      >
        <DialogTitle>
 <ClearIcon sx={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                color: "#fff",
                                "&:hover": {
                                    color: "red",
                                    cursor: "pointer",
                                },
                            }} onClick={closeDocumentList} />
        </DialogTitle>
        <DialogContent>
        {file_field ?  
        <>         
        <Button variant="outlined" sx={ theme.mainButton.root} onClick={openFile}>View Current File</Button>
          {file_valid? <p style={{color:"#fff"}}>Your file has been validated</p> : <p style={{color:"#fff"}}>Your file has not been validated, please ensure the correct file has been uploaded</p>}
        </>
 :
          <Button variant="disabled" style={{color:"#fff"}} >No current file</Button> 
          }
          <br/> <br/>
          {!file_valid?
          <>
          <Typography style={{color:"#fff"}} >Upload a new file for {file_name}</Typography>

          <Grid
            container
            component="form"
            encType="multipart/form-data"
            onSubmit={handleSubmit}
            sx={{ "& button": { m: 2 } }}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
      
            <Grid item xs={12} align="center" style={{ marginBottom: 10 }}>

              <TextField
                InputLabelProps={{ shrink: true }}
                margin="normal"
                required
                fullWidth
                className="inputRounded"
                id="file"
                label="File"
                name="file"
                type="file"
              // value={file_field || ""}
              /></Grid>
            <Grid item xs={12} align="center" style={{ marginBottom: 10 }}>
              <Button sx={ theme.mainButton.root} type="submit"  >
                upload
              </Button></Grid>
          </Grid> </>
          : <></>}

        </DialogContent>
        <DialogActions>

          {/* <Button sx={ theme.mainButton.root}  onClick={openDocumentsList}>View All Documents</Button> */}

        </DialogActions>
      </Dialog>




      <div >
            <Dialog 
              BackdropProps={{ invisible: true }}
              PaperProps={{ sx: { width: "50%", height: "100%" }, style: {
                backgroundColor: "#000",
                boxShadow: "none"
              }, }}        
              style={theme.modalStyle.root}
            open={openFileUpload} 
            onClose={closeFile}
            >
                <DialogTitle >
 
                        <Typography style={theme.modalHeading.root}>
                            Upload Documents
                        </Typography>
                  
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                    
                    {userFileListData.details.map((item) =>(
                        <Grid key={item.description} container>
                            <Grid item xs={11}> 
                                <Button 
                                fullWidth
                                variant="contained" 
                               
                                // endIcon={}
                                style={theme.mainButton.root}
                                sx={{mt:1}}
                                onClick={() => openDocumentsList(item)}
                                >
                                    {item.doc_name} &nbsp; &nbsp; &nbsp;
                                    {item.valid? <CheckCircleOutlineIcon sx={{ color: "#37fa7e" }}/> : <WarningIcon sx={{ color: "#e67e00" }}/>}
                                    
                                </Button>
                                
                            </Grid> 
           
     
                            <Grid item xs={1}> <br/><InfoIcon  style={{ color:"#fff"}} onClick={() => openInfo(item.file_details)} /></Grid> 
                        </Grid>
                    ))}
                    </Grid>
                     
                </DialogContent>
                <DialogActions>
                    <Button 
                    variant="contained" 
                    color="error" 
                    style={theme.subButton.root}
                    onClick={closeFile}
                    sx={{mr:2}}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
           
            </div>




            <Dialog
        BackdropProps={{ invisible: true }}
        PaperProps={{
                 
          style: {
            backgroundColor: "#000",
            boxShadow: "none",
            borderWidth: 2,
            borderRadius: 10,
            borderColor: "#fff",
            borderStyle: "solid",
            backgroundColor: "#101010",
            color: "#fff"
  
          },
        }}

        open={Open}
        onClose={closeInfo}
      >
        <DialogTitle>
          <ClearIcon sx={{ top: 0, right: 0 }} onClick={closeInfo} />

        </DialogTitle>
        <DialogContent>
        <p> {file_details}</p>

              
      </DialogContent>
        <DialogActions>

          {/* <Button sx={ theme.mainButton.root}  onClick={openDocumentsList}>View All Documents</Button> */}

        </DialogActions>
      </Dialog>
        </>
    );
  } else {
      return <></>
  }
  
}






