import React from "react";
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import ClearIcon from "@mui/icons-material/Clear";
import { Grid, CardActions } from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import theme from "../../../../../../Globals/styling/theme";
import { useLocation } from "react-router-dom";

export default function ModalAssignProjToUser() {
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const [open, setopen] = useState(false);
    const [Open, setOpen] = useState(false);

    const handleClick = (e) => {
        e.stopPropagation();
        setopen(true);
    };

    const closeModal = (e) => {
        e.stopPropagation();
        setopen(false);
    };

    return (
        <>
            <InfoIcon
                style={{ color: "#13cbc5", zIndex: 3 }}
                sx={{
                    "&:hover": {
                        color: "white",
                        cursor: "pointer",
                    },
                }}
                onClick={(e) => handleClick(e)}
            />

            <Dialog
                BackdropProps={{ invisible: true }}
                PaperProps={{
                    style: {
                        backgroundColor: "#000",
                        boxShadow: "none",
                        borderWidth: 2,
                        borderRadius: 10,
                        borderColor: "#fff",
                        borderStyle: "solid",
                        backgroundColor: "#101010",
                        color: "#fff",
                    },
                }}
                style={theme.modalStyle.root}
                open={open}
                onClose={closeModal}
            >
                <DialogTitle>
                    <ClearIcon
                        sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            color: "#fff",
                            "&:hover": {
                                color: "red",
                                cursor: "pointer",
                            },
                        }}
                        onClick={closeModal}
                    />
                </DialogTitle>
                <DialogContent>
                    <>
                        <Typography
                            sx={{ mb: 1 }}
                            variant="h6"
                            style={{ color: "#fff" }}
                        >
                            Assign User To Project Info
                        </Typography>
                        <Grid
                            container
                            sx={{ "& button": { m: 2 } }}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Grid
                                item
                                xs={12}
                                align="center"
                                style={{ marginBottom: 10 }}
                            >
                                <p>
                                    Only sites where all required documentation
                                    are added and safety file index are
                                    completed will be able to selected from
                                    here.
                                </p>
                                <p>
                                    The Project Manager needs to confirm and
                                    prepare this before inviting
                                    employees/subcontractors to the project and
                                    must be mentioned in the kick-off meeting.
                                </p>
                            </Grid>
                        </Grid>{" "}
                    </>
                </DialogContent>
                <DialogActions></DialogActions>
            </Dialog>
        </>
    );
}
