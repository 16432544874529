import { createSlice, createAsyncThunk } from "@reduxjs/toolkit/";
import { clearCookie, getCookie, setCookie } from "../api/apiHelper";

const LOGIN_URL = `${process.env.REACT_APP_SECRET_API}/auth/login/`;

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const loginUser = createAsyncThunk("login/loginUser", async (body) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },

    body: JSON.stringify(body),
  };
  const response = await fetch(LOGIN_URL, requestOptions);

  return response.json();
});

const initialState = getCookie("access")
  ? {
      token: `Bearer ${getCookie("access")}`,
      isLoggedIn: true,
      isLoginError: false,
      PampManager: parseJwt(getCookie("access")).PampManager,
      PampClient: parseJwt(getCookie("access")).PampClient,
      ProfessionalEngineer: parseJwt(getCookie("access")).ProfessionalEngineer,
      Maintenance: parseJwt(getCookie("access")).Maintenance,
      ComplianceOfficer: parseJwt(getCookie("access")).ComplianceOfficer,
      Internal : parseJwt(getCookie("access")).internal,
      External : parseJwt(getCookie("access")).external,
    }
  : { token: "",
      isLoggedIn: false,
      isLoginError: false
    };

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.isLoggedIn = false;
      state.token = "";
      clearCookie();
    },
  },
  extraReducers(builder) {
    builder.addCase(loginUser.fulfilled, (state, action) => {
      let response = action.payload;
      if (response.access) {
        state.token = response.access;
        state.isLoggedIn = true;
        setCookie(response);
        state.PampManager= parseJwt(getCookie("access")).PampManager;
        state.PampClient= parseJwt(getCookie("access")).PampClient;
        state.ProfessionalEngineer= parseJwt(getCookie("access")).ProfessionalEngineer;
        state.Maintenance= parseJwt(getCookie("access")).Maintenance;
        state.ComplianceOfficer= parseJwt(getCookie("access")).ComplianceOfficer;
        state.Internal = parseJwt(getCookie("access")).internal;
        state.External = parseJwt(getCookie("access")).external;
        state.isLoginError = false;
      } else {
        state.isLoginError = true;
      }
    });
  },
});

export const getToken = (state) => state.auth.token;
export const checkIsLoggedIn = (state) => state.auth.isLoggedIn;
export const checkIsLoginError = (state) => state.auth.isLoginError;
export const getManager = (state) => state.auth.PampManager;
export const getClient = (state) => state.auth.PampClient;
export const getProfessionalEngineer = (state) => state.auth.ProfessionalEngineer;
export const getComplianceOfficer = (state) => state.auth.ComplianceOfficer;
export const getMaintenance = (state) => state.auth.Maintenance;
export const getInternal = (state) => state.auth.Internal;
export const getExternal = (state) => state.auth.External;

export const { logout } = authSlice.actions;

export default authSlice.reducer;
