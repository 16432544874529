import * as React from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import { useNavigate } from "react-router-dom";
import { useGetUserDetailsQuery } from "../../../../api/apiSlice";
import TableUserDocuments from "./TableUserDoc";
//     getModalOpen,  setModalOpen, 
//   } from "./siteSlice";
import Footer from "../../../../Footer/Footer";

export default function LandingTableUserDocuments() {
  let navigate = useNavigate();
  const
  {
  data: userData,
  isLoading: userLoading,
  isSuccess: userSuccess,
  isError: userError,
  error: userMessage,
} = useGetUserDetailsQuery();

if(userSuccess){
  return (
    <>
    {/* {console.log(userData.user_details)} */}

   <TableUserDocuments user_details={userData}/>
   <br/><br/><br/>
   <Footer/>

    </>
  );
}
else return (<></>);
}
