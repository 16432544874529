import React from "react";
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import ClearIcon from "@mui/icons-material/Clear";
import { Grid, CardActions, IconButton } from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import theme from "../../../../Globals/styling/theme";
import { useLocation } from "react-router-dom";

export default function GroupInfo() {
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const [open, setopen] = useState(false);

    const handleClick = (e) => {
        e.stopPropagation();
        setopen(true);
    };

    const closeModal = (e) => {
        e.stopPropagation();
        setopen(false);
    };

    return (
        <>
            <InfoIcon
                style={{ color: "#13cbc5", zIndex: 3 }}
                sx={{
                    "&:hover": {
                        color: "white",
                        cursor: "pointer",
                    },
                }}
                onClick={(e) => handleClick(e)}
            ></InfoIcon>

            <Dialog
                BackdropProps={{ invisible: true }}
                PaperProps={{
                    style: {
                        backgroundColor: "#000",
                        boxShadow: "none",
                        borderWidth: 2,
                        borderRadius: 10,
                        borderColor: "#fff",
                        borderStyle: "solid",
                        backgroundColor: "#101010",
                        color: "#fff",
                    },
                }}
                style={theme.modalStyle.root}
                open={open}
                onClose={closeModal}
            >
                <DialogTitle>
                    <ClearIcon
                        sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            color: "#fff",
                            "&:hover": {
                                color: "red",
                                cursor: "pointer",
                            },
                        }}
                        onClick={closeModal}
                    />
                </DialogTitle>
                <DialogContent>
                    <>
                        <Typography
                            sx={{ mb: 1 }}
                            variant="h5"
                            style={{ color: "#fff" }}
                        >
                            Group Info
                        </Typography>
                        <Typography variant="h6" style={{ color: "#fff" }}>
                            Main Authorisation
                        </Typography>
                        <p>
                            At least and only one (1) main authorisation group
                            needs to be assigned to a user.
                        </p>
                        <ul>
                            <li>
                                PAMP Manager: <br />A PAMP Manager can access
                                and maintain every aspect of the PAMP
                                application. This group should only be assigned
                                to internal CrEng employees to setup and manage
                                projects, create and assign authorisations to
                                other users, assign users to projects as well as
                                review any and all projects and related assets.
                            </li>
                            <li>
                                Team Member: <br />A Team Member can only access
                                projects and assets to which they have been
                                assigned to by a PAMP Manager. This group should
                                be assigned to users external to CrEng such that
                                they cannot access other projects for other
                                clients to which they have not been contracted
                                to assess those assets.
                            </li>
                            <li>
                                Maintenance: <br />A Maintenance user can only
                                access the maintenance portion of projects and
                                assets to which they have been assigned to by a
                                PAMP Manager. This group should be assigned to
                                users external to CrEng who are contractors only
                                designated to action observations made during an
                                inspection such that they cannot access any
                                other information about the project or asset.
                            </li>
                        </ul>
                        <Typography variant="h6" style={{ color: "#fff" }}>
                            Auxilliary Authorisation
                        </Typography>
                        <p>
                            Any amount of auxilliary authorisations can be
                            assigned to a user. These authorisations dictate
                            what the user can do extra within their main
                            authorisation.
                        </p>
                        <ul>
                            <li>
                                PrEng: <br />
                                Only a professional engineer has the ability to
                                maintain/add/remove standard observations in the
                                PAMP application from the asset screen. In
                                addition only a professional engineer can
                                approve observations made during an insection or legal compliance items made/approved by a compliance officer for
                                reporting.
                            </li>
                            <li>
                                Compliance Officer: <br />Only a compliance officer has the ability to approve legal compliance items for a PrEng to review and approve.
                            </li>
                        </ul>
                    </>
                </DialogContent>
                <DialogActions></DialogActions>
            </Dialog>
        </>
    );
}
