import { Routes, Route } from "react-router-dom";

import React from "react";

import AppList from "../../Features/AppList/AppListCT";
import LoginPage from "../../Features/Authentication/LoginPage";
import PrivateRoute from "./PrivateRoute";
import ResponsiveAppBar from "../components/ResponsiveAppBar"
import {ErrorNotification} from "../../Features/Notifications/ErrorNotification"
import {SuccessNotification} from "../../Features/Notifications/SuccessNotification"
import DEMButton from "../components/DEMButton";
import ComingSoon from "../../Features/AppList/ComingSoon";
import { useSelector, useDispatch } from "react-redux";
import { checkIsLoggedIn } from "../../Features/Authentication/authSlice";
import LandingUsers from "../../Features/AppList/Userport/LandingPage";
import LandingOnboardUsers from "../../Features/AppList/Onboarding/Manager/TableOnboard/LandingPage";
import OnboardingPage from "../../Features/AppList/Onboarding/LandingPage";
import LandingTableUserDocuments from "../../Features/AppList/Onboarding/User/TableDocuments/LandingPage";
import LandingTableManagerDocuments from "../../Features/AppList/Onboarding/Manager/TableDocuments/LandingPage";
import Footer from "../../Features/Footer/Footer";
import PageProfile from "../components/Profile"
import LandingTableUserProjectDocuments from "../../Features/AppList/Onboarding/User/TableProjects/LandingPage";
import {Helmet} from 'react-helmet';

export default function MainRouter(props) {
  const isLoggedIn = useSelector(checkIsLoggedIn);
  return (
    <>
      {/* Components to load if user is authenticated */}
      {isLoggedIn ? (
        <> <Helmet>   
    {/* <style>{'body { background-color: #474748; }'}</style> */}
    <style>{'body { background-color: #000; }'}</style>
    </Helmet>
          <ResponsiveAppBar />
          <SuccessNotification />
          <ErrorNotification />
          <DEMButton />
        
        </>
      ) : (
        <></>
      )}

      <Routes>
        {/* Private Routes */}
        <Route element={<PrivateRoute />}>
          <Route exact path="/" element={<AppList />} />
          <Route exact path="/userport/users" element={<LandingUsers />}></Route>
          <Route exact path="/onboard" element={<OnboardingPage />}></Route>
          <Route exact path="/onboard/usersList/documents" element={<LandingTableManagerDocuments />}></Route>
          <Route exact path="/onboard/users" element={<LandingOnboardUsers />}></Route>
          <Route exact path="/onboard/users/documents" element={<LandingTableUserDocuments />}></Route>
          <Route exact path="/onboard/users/project/documents" element={<LandingTableUserProjectDocuments />}></Route>

          <Route exact path="/profile" element={<PageProfile />}></Route>
         
        </Route>

        {/* Public Routes */}
        <Route exact path="/login" element={<LoginPage />}></Route>
        <Route exact path="/comingsoon" element={<ComingSoon />}></Route>
 
      </Routes>
       
    </>
  );
}
