import React from "react";
import { hideError, getIsError, getErrorMessage } from "./notificationsSlice";

import { useSelector, useDispatch } from "react-redux";

import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import MuiAlert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ErrorNotification = () => {
  const dispatch = useDispatch();
  const isError = useSelector(getIsError);
  const errorMessage = useSelector(getErrorMessage)

  function handleClose() {
    dispatch(hideError());
  }

  return (
    <>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar open={isError} anchorOrigin={{ vertical: "bottom", horizontal: "left" }} TransitionComponent={Transition} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            {errorMessage}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};
