import React from "react";
import Button from "@mui/material/Button";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useSelector, useDispatch } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import {
    showError,
    showSuccess,
} from "../../../../Notifications/notificationsSlice";

import Checkbox from "@mui/material/Checkbox";

import TextField from "@mui/material/TextField";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import theme from "../../../../../Globals/styling/theme";
import {
    useGetSitesForProjectAssignQuery,
    useAssignUserToSiteMutation,
} from "../../../../api/apiSlice";
import {
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    FormControl,
    Select,
} from "@mui/material";
import ModalAssignProjToUser from "./Info/ModalAssignProjToUser";
// import { MenuProps, useStyles, options } from "./utils";

export default function ButtonAssignUser({ user }) {
    const [
        newSiteToUser,
        {
            data: newData,
            isLoading: newLoading,
            isSuccess: newSuccess,
            isError: newError,
            error: newMessage,
        },
    ] = useAssignUserToSiteMutation();

    const {
        data: siteData,
        isLoading: siteLoading,
        isSuccess: siteSuccess,
        isError: siteError,
        error: siteMessage,
    } = useGetSitesForProjectAssignQuery();

    // console.log(siteData)
    const dispatch = useDispatch();

    const navigate = useNavigate();

    let temp = "";
    // const open = useSelector(getEntity);

    const [useremail, setuseremail] = useState("");
    const [site, setSite] = useState("");
    const [open, setOpen] = useState(false);
    const [eng, setEng] = useState(false);
    const [onsite, setonsite] = useState(false);
    const [maintenance, setMaintenance] = useState(false);

    const type = ["Employee", " Subcontractor"];
    const [currtype, setType] = useState("");

    const handleTypeChange = (event) => {
        setType(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = () => {
        setOpen(true);

        // {siteData.details.map((item) => (console.log(item.id) ))}

        // console.log(user.id)
        // {user.groups.map(filteredName => (
        //   console.log(filteredName.name.includes("Maint"))
        // ))}
    };

    useEffect(() => {
        if (newSuccess) {
            let message = `${newData.success}`;
            dispatch(showSuccess(message));
        }
        if (newError) {
            let message = `${newMessage.data.error}`;
            dispatch(showError(message));
        }
    }, [newSuccess, newError]);

    const handleOnSubmit = (event) => {
        event.preventDefault();
        const body = {
            user_id: user.id,
            maintenance: maintenance,
            preng: eng,
            on_site: onsite,
        };
        const request = { body: body, id: currtype };
        newSiteToUser(request);
        setOpen(false);
    };

    if (siteLoading) {
        return <div className="center-screen">{/* <LoadingSpinner /> */}</div>;
    } else if (siteError) {
        return <div className="center-screen">{/* <Error500 /> */}</div>;
    } else {
        return (
            <>
                {/* {user.groups.map(filteredName => (
  console.log(filteredName.name.includes("Engineer"))
))} */}
                {/* {console.log(user.id)} */}
                {/* {siteData.details.map((item) => (
                     console.log(item.site_docs.name)
                  ))} */}
                <Button
                    sx={theme.mainButton.root}
                    onClick={() => handleClick()}
                >
                    Assign to Projects
                </Button>

                <Dialog
                    BackdropProps={{ invisible: true }}
                    PaperProps={{
                        style: {
                            backgroundColor: "#000",
                            boxShadow: "none",
                            borderWidth: 2,
                            borderRadius: 10,
                            borderColor: "#fff",
                            borderStyle: "solid",
                            backgroundColor: "#101010",
                            color: "#fff",
                        },
                    }}
                    style={theme.modalStyle.root}
                    open={open}
                    onClose={handleClose}
                    fullWidth
                >
                    <DialogTitle>
                        <Typography style={theme.modalHeading.root}>
                            Assign projects
                        </Typography>{" "}
                        <ModalAssignProjToUser />
                    </DialogTitle>
                    <form onSubmit={(e) => handleOnSubmit(e)}>
                        <DialogContent>
                            <br />

                            <FormControl
                                fullWidth
                                sx={{ mt: 2 }}
                                className="inputRounded"
                            >
                                <InputLabel id="outlined-basic">
                                    Project Selection
                                </InputLabel>

                                <Select
                                    labelId="type-select-label"
                                    id="type-select"
                                    value={currtype}
                                    label="Project Selection"
                                    onChange={(e) => setType(e.target.value)}
                                    // sx={{ width: '300px'}}
                                >
                                    {siteData?.details.map((item) => (
                                        <MenuItem
                                            key={item.site_docs.name}
                                            value={item.site_docs.id}
                                        >
                                            {(setSite) => item}
                                            {/* {console.log(site)} */}
                                            {item.site_docs.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <Typography style={{ color: "#fff" }}>
                                On-Site
                                <Checkbox
                                    checked={onsite}
                                    onChange={(e) =>
                                        setonsite(e.target.checked)
                                    }
                                    inputProps={{ "aria-label": "controlled" }}
                                    style={{
                                        color: "#13cbc5",
                                    }}
                                />
                            </Typography>

                            {user.groups.map((filteredName) =>
                                filteredName.name.includes("Maint") ? (
                                    <Typography style={{ color: "#fff" }}>
                                        Maintenance Manager
                                        <Checkbox
                                            checked={maintenance}
                                            onChange={(e) =>
                                                setMaintenance(e.target.checked)
                                            }
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                            style={{
                                                color: "#13cbc5",
                                            }}
                                        />
                                    </Typography>
                                ) : (
                                    <></>
                                )
                            )}

              {user.groups.map((filteredName) =>
                filteredName.name.includes("Professional Engineer") ? (
                  <Typography style={{color:"#fff"}}>
                    PrEng
                    <Checkbox
                      checked={eng}
                      onChange={(e) => setEng(e.target.checked)}
                      inputProps={{ "aria-label": "controlled" }}
                      style ={{
                        color: "#13cbc5",
                      }}
                    />
                  </Typography>
                ) : (
                  <></>
                )
              )}
            </DialogContent>
         
          <DialogActions>
            <Button
              type="submit"
              variant="outlined"
              sx={ theme.mainButton.root}
              align="middle"
            >
              Submit
            </Button>
            {/* onClick={() => handleonSubmit(selected)} */}
          </DialogActions>
          </form>
        </Dialog>
      </>
    );
  }
}
