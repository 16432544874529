import * as React from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CardActions } from "@mui/material";
import {  getClient, getManager } from "../../Authentication/authSlice";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import Footer from "../../Footer/Footer";
import { useGetUserDetailsQuery } from "../../api/apiSlice";
export default function OnboardingPage() {
  let navigate = useNavigate();

  const handleGoToManageOnboarding = (event) => {
    navigate("/onboard/users/");
  };

  const managerDocuments = (event) => {
    navigate("/onboard/usersList/documents/");
  };

  const clientDocuments = (event) => {
    navigate("/onboard/users/documents/");
  };

  const projectDocuments = (event) => {
    navigate("/onboard/users/project/documents/");
  };

  const is_client = useSelector(getClient);
  const is_manager = useSelector(getManager);



  return (
    <>
      <Grid
        container
        spacing={{ xs: 1, sm: 1, md: 1 }}
        columns={{ xs: 2, sm: 4, md: 8 }}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >



        {is_manager ?
          <Grid item align="center" sx={{ mb: 1 }}>
            <Card
              onClick={managerDocuments}
              sx={{
                maxWidth: 175,
                maxHeight: 200,
                minWidth: 150,
                minHeight: 300,
                display: "flex",
                justiyContent: "space-between",
                flexDirection: "column",
  background: '#6C6B6D',
                cursor: 'pointer', color: '#fff',
                '&:hover':
                {
                  color: '#000',
                  background: 'linear-gradient(45deg, #6C6B6D 30%, #e2e1e2 90%)',
                  cursor: 'pointer'
                },
              }}
              elevation={10}
            >
              <CardContent>
                <Typography align="center" variant="h6" sx={{ mb: -1.5 }}>
                  Documents
                </Typography>
                <br />
                <FileCopyIcon sx={{ fontSize: 40, mt :3,  color: "#fff" }} />

                <Typography variant="body2">
                  <br />
                  View and Add onboarding documents
                </Typography>
                <br />
              </CardContent>
              <CardActions>

              </CardActions>
            </Card>
          </Grid>
          :
          <Grid item align="center" sx={{ mb: 1 }}>
            <Card
              onClick={clientDocuments}
              sx={{
                maxWidth: 175,
                maxHeight: 200,
                minWidth: 150,
                minHeight: 300,
                display: "flex",
                justiyContent: "space-between",
                flexDirection: "column",
  background: '#6C6B6D',
                cursor: 'pointer', color: '#fff',
                '&:hover':
                {
                  color: '#000',
                  background: 'linear-gradient(45deg, #6C6B6D 30%, #e2e1e2 90%)',
                  cursor: 'pointer'
                },
              }}
              elevation={10}
            >
              <CardContent>
                <Typography align="center" variant="h6" sx={{ mb: -1.5 }}>
                 Onboarding Documents
                </Typography>
                <br />
                <FileCopyIcon sx={{ fontSize: 40, mt :3,  color: "#fff" }} />

                <Typography variant="body2">
                  <br />
                  View and Add onboarding documents
                </Typography>
                <br />
              </CardContent>
              <CardActions>

              </CardActions>
            </Card>
          </Grid>
        }

        {is_manager ?
          <Grid item align="center" sx={{ mb: 1 }}>
            <Card
              onClick={handleGoToManageOnboarding}
              sx={{
                maxWidth: 175,
                maxHeight: 200,
                minWidth: 150,
                minHeight: 300,
                display: "flex",
                justiyContent: "space-between",
                flexDirection: "column",
  background: '#6C6B6D',
                cursor: 'pointer', color: '#fff',
                '&:hover':
                {
                  color: '#000',
                  background: 'linear-gradient(45deg, #6C6B6D 30%, #e2e1e2 90%)',
                  cursor: 'pointer'
                },
              }}
              elevation={10}
            >
              <CardContent>
                <Typography align="center" variant="h6" sx={{ mb: -1.5 }}>
                  Onboard
                </Typography>
                <br />
                <PersonAddAlt1Icon sx={{ fontSize: 40, mt :3,  color: "#fff" }} />

                <Typography variant="body2">
                  <br />
                  Onboard Employees to respective projects
                </Typography>
                <br />
              </CardContent>
              <CardActions>

              </CardActions>
            </Card>
          </Grid>

          : 
          <Grid item align="center" sx={{ mb: 1 }}>
          <Card
            onClick={projectDocuments}
            sx={{
              maxWidth: 175,
              maxHeight: 200,
              minWidth: 150,
              minHeight: 300,
              display: "flex",
              justiyContent: "space-between",
              flexDirection: "column",
background: '#6C6B6D',
              cursor: 'pointer', color: '#fff',
              '&:hover':
              {
                color: '#000',
                background: 'linear-gradient(45deg, #6C6B6D 30%, #e2e1e2 90%)',
                cursor: 'pointer'
              },
            }}
            elevation={10}>
            <CardContent>
              <Typography align="center" variant="h6" sx={{ mb: -1.5 }}>
               Project Specific Documents
              </Typography>
              <br />
              <FileCopyIcon sx={{ fontSize: 40, mt :3,  color: "#fff" }} />

              <Typography variant="body2">
                <br />
                View and Add project documents
              </Typography>
              <br />
            </CardContent>
            <CardActions>

            </CardActions>
          </Card>
        </Grid>
          
          }

      </Grid>

<Footer/>
    </>
  );
}
