import React from "react";
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import ClearIcon from "@mui/icons-material/Clear";
import { Grid, CardActions } from "@mui/material";
import theme from "../../../../../Globals/styling/theme"
import TextField from '@mui/material/TextField';
import Checkbox from "@mui/material/Checkbox";
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useGetFilesForInternalExternalManagerQuery , useUploadFileForInternalExternalMutation,useGetUserDetailsQuery, useUpdateFileValidationMutation} from "../../../../api/apiSlice";
import EditIcon from '@mui/icons-material/Edit';
import { showError, showSuccess } from "../../../../Notifications/notificationsSlice";
export default function ButtonDocuments({user}) {

    const dispatch = useDispatch();

    const navigate = useNavigate();
    const [open, setopen] = useState(false);
    const [Open, setOpen] = useState(false);
    const [file_name, setfile_name] = useState("");
    const [details, setDetails] = useState("");
    const [fileid, setfile_id] = useState("");
    const [file, setfile] = useState("");

    const [valid, setValid] = useState('');
    const [myArray, setMyArray] = useState([]);
  
    const [openFileUpload, setopenFile] = useState(false);
    const [ updateValidation, { data: update_data, isSuccess: isUpdateValidationsSuccess, error: update_error, isError: isUpdateValidationsError } ] = useUpdateFileValidationMutation();

    const
  {
  data: userData,
  isLoading: userLoading,
  isSuccess: userSuccess,
  isError: userError,
  error: userMessage,
} = useGetFilesForInternalExternalManagerQuery(user.id);

const
{
data: userIDData,
isLoading: userIDLoading,
isSuccess: userIDSuccess,
isError: userIDError,
error: userIDMessage,
} = useGetUserDetailsQuery();
// const
//       {
//       data: userValid,
//       isLoading: userValidLoading,
//       isSuccess: userValidSuccess,
//       isError: userValidError,
//       error: userValidMessage,
//     } = useGetFileForInternalExternalValidationQuery(user.id, fileid);

    const handleClick = () => {
      setopenFile(true);

  };
   
  
    const openFile = (id) => {
      let file_open = userData.details.find(x => x.id === id).file;
      window.open(file_open);
    };
  
    const openFileFor = (id, name, valid, temp) => {
      
      setValid(valid)
      setOpen(true)
      setfile_name(name)
      setfile_id(id)
    

      setValid(valid)
      setDetails(temp.file_details)
      setfile(temp.file)
     

      let body = { 
        'valid': valid,
        
    };        
    updateValidation({ body: body, id: user.id, file_id: temp.id  })
  
    };

    const handleValidation = (validate) => {
      setValid(validate)
     
      console.log("validation")
      console.log(validate)
     
      let body = { 
        'valid': validate,
        
    };        
    updateValidation({ body: body, id: user.id, file_id: fileid  })
    console.log(body)
  }; 

    const openDocumentsList = () => {
      setopen(true);
      
    };
    const closeDocumentList = () => {
      setopen(false);
    };
  
    const closeFile = () => {
      setOpen(false);
    };
    const [newFileUpload, 
      {
      data: newFileData,
      isLoading: newFileLoading,
      isSuccess: newFileSuccess,
      isError: newFileError,
      error: newFileMessage,
  },
  
  ] = useUploadFileForInternalExternalMutation();

  React.useEffect(() => {
    if (newFileSuccess) {
        // console.log(newFileData)
        let message = `${newFileData.success}`;
        dispatch(showSuccess("Succesfully Uploaded"));
        window.location.reload(true);
    }
    if (newFileError) {
        // console.log(newFileMessage)
        let message = `${newFileMessage.data.error}`;
        dispatch(showError("Could not upload file"));
    }
  }, [newFileSuccess, newFileError]);

    const handleSubmit = (event) => {
      event.preventDefault();
      const body = new FormData(event.currentTarget);
      const request = { body: body, id: fileid };
      newFileUpload(request);
     
      setOpen(false);
      setopen(false);
     
    };

    React.useEffect(() => {
     
     
    }, [isUpdateValidationsSuccess]);

    if (userLoading ||userIDLoading) {
      return (
          <div className="center-screen">
              {/* <LoadingSpinner /> */}
          </div>
      );
    } else if (userError||userIDError ) {
      return (
          <div className="center-screen">
              {/* <Error500 /> */}
          </div>
      );
    } else {
    
    return (
        <>
            <Button sx={ theme.mainButton.root} onClick={() => openDocumentsList()}>
                Documents
            </Button>



      <Dialog
        BackdropProps={{ invisible: true }}
               PaperProps={{
                 
        style: {
          backgroundColor: "#000",
          boxShadow: "none",
          borderWidth: 2,
          borderRadius: 10,
          borderColor: "#fff",
          borderStyle: "solid",
          backgroundColor: "#101010",
          color: "#fff"

        },
      }}
        style={theme.modalStyle.root}
        open={open}
        onClose={closeDocumentList}
      >
        <DialogTitle>
          <ClearIcon sx={{ top: 0, right: 0 }} onClick={closeDocumentList} />
          <Typography style={theme.modalHeading.root}>All Files </Typography>

        </DialogTitle>
        <DialogContent>
        {userData.details.map((temp) => (
          // temp.file ?
          
          <>  <br/><Button style={theme.mainButton.root}  onClick={() => openFileFor(temp.id, temp.doc_name, temp.valid, temp)} /*sx={theme.tableButtons} */>
              {temp.doc_name}
              
            </Button> <br/> </> 
            // : <> <br/> <Button disabled>{temp.doc_name}</Button> <br/> </>
           ))} 
        </DialogContent>
        <DialogActions>

        </DialogActions>
      </Dialog>


      <Dialog
        BackdropProps={{ invisible: true }}
               PaperProps={{
                 
        style: {
          backgroundColor: "#000",
          boxShadow: "none",
          borderWidth: 2,
          borderRadius: 10,
          borderColor: "#fff",
          borderStyle: "solid",
          backgroundColor: "#101010",
          color: "#fff"

        },
      }}        
        style={theme.modalStyle.root}
        open={Open}
        onClose={closeFile}
      >
        <DialogTitle>
        <ClearIcon sx={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                color: "#fff",
                                "&:hover": {
                                    color: "red",
                                    cursor: "pointer",
                                },
                            }} onClick={closeFile} />
          {(user.id == userIDData.user_details.id)?
          <> <Typography style={theme.modalHeading.root}>Upload or view File </Typography></>
          :
          <Typography style={theme.modalHeading.root}>Validate or view File </Typography>
          }
        </DialogTitle>
        <DialogContent>
       
          {file ?
          <>  <br/><Button sx={ theme.mainButton.root}  onClick={() => openFile(fileid)} /*sx={theme.tableButtons} */>
              View Uploaded {file_name}
              
            </Button> <br/> </> :
               <>  <br/><Button disabled style={{color:"#fff"}} /*sx={theme.tableButtons} */>
               No current file
               
             </Button> <br/> </>
    }
          

          {(user.id == userIDData.user_details.id)?
          <>
          <Typography style={{color:"#fff"}} >Upload a new file for {file_name}</Typography>

          <Grid
            container
            component="form"
            encType="multipart/form-data"
            onSubmit={handleSubmit}
            sx={{ "& button": { m: 2 } }}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
      
            <Grid item xs={12} align="center" style={{ marginBottom: 10 }}>

              <TextField
                InputLabelProps={{ shrink: true }}
                margin="normal"
                required
                fullWidth
                className="inputRounded"
                id="file"
                label="File"
                name="file"
                type="file"
              // value={file_field || ""}
              /></Grid>
            <Grid item xs={12} align="center" style={{ marginBottom: 10 }}>
              <Button sx={ theme.mainButton.root} type="submit"  >
                upload
              </Button></Grid>
          </Grid> </>
          : <>
                    <Typography style={{color:"#fff"}}>
                            Validate
                            <Checkbox
                                checked={valid}
                                onChange={(e) =>
                                    handleValidation(e.target.checked)
                                }
                                inputProps={{ "aria-label": "controlled" }}
                                style ={{
                                  color: "#13cbc5",
                                }}
                            />
                        </Typography>
          </>}

 

                        <br/> <br/> <br/>
                        <h2 style={{color:"white"}}>Info:</h2>
                       <p style={{color:"white"}}>{details} &nbsp; &nbsp; &nbsp; </p>
        
        <DialogActions>

        </DialogActions></DialogContent></Dialog>
     

        </>
    );
}
}
