export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
export function concatPathWithParams(path, queryParams) {
  let pathWithParams = path;

  if (queryParams && Object.keys(queryParams).length) {
    pathWithParams += `?${_queryParamsToQueryString(queryParams)}`;
  }

  return pathWithParams;
}

function _queryParamsToQueryString(queryParams) {
  return Object.keys(queryParams)
    .filter((key) => queryParams[key] !== undefined)
    .map((key) => `${key}=${encodeURIComponent(queryParams[key])}`)
    .join("&");
}

export function getToken() {
  const token = `${getCookie("access")}`;
  return token;
}

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export function setCookie(token) {
  // let manager = parseJwt(token.access).Manager;
  // let developer = parseJwt(token.access).Developer;
  // let PampClient = parseJwt(token.access).PampClient;
  // let PampManager = parseJwt(token.access).PampManager;
  // let ProjectManager = parseJwt(token.access).ProjectManager;
  // let ProfessionalEngineer = parseJwt(token.access).ProfessionalEngineer;
  // let Maintenance = parseJwt(token.access).Maintenance;
  // let ComplianceOfficer = parseJwt(token.access).ComplianceOfficer;
  document.cookie = `refresh=${token.refresh};max-age=86400;domain=${process.env.REACT_APP_SECRET_DOMAIN};path=/`;
  document.cookie = `access=${token.access};max-age=57600;domain=${process.env.REACT_APP_SECRET_DOMAIN};path=/`;
  // if (manager) {
  //   document.cookie = `manager=${manager};max-age=57600;domain=${process.env.REACT_APP_SECRET_DOMAIN};path=/`;
  // }
  // if (developer) {
  //   document.cookie = `developer=${developer};max-age=57600;domain=${process.env.REACT_APP_SECRET_DOMAIN};path=/`;
  // }

  // if (PampClient) {
  //   document.cookie = `PampClient=${PampClient};max-age=57600;domain=${process.env.REACT_APP_SECRET_DOMAIN};path=/`;
  // }

  // if (PampManager) {
  //   document.cookie = `PampManager=${PampManager};max-age=57600;domain=${process.env.REACT_APP_SECRET_DOMAIN};path=/`;
  // }

  // if (ProjectManager) {
  //   document.cookie = `PampManager=${PampManager};max-age=57600;domain=${process.env.REACT_APP_SECRET_DOMAIN};path=/`;
  // }
  // if (ProfessionalEngineer) {
  //   document.cookie = `ProfessionalEngineer=${ProfessionalEngineer};max-age=57600;domain=${process.env.REACT_APP_SECRET_DOMAIN};path=/`;
  // }
  // if (Maintenance) {
  //   document.cookie = `Maintenance=${Maintenance};max-age=57600;domain=${process.env.REACT_APP_SECRET_DOMAIN};path=/`;
  // }
  // if (ComplianceOfficer) {
  //   document.cookie = `ComplianceOfficer=${ComplianceOfficer};max-age=57600;domain=${process.env.REACT_APP_SECRET_DOMAIN};path=/`;
  // }
}
export function clearCookie() {
  document.cookie = `access='';expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=${process.env.REACT_APP_SECRET_DOMAIN};path=/`;
  document.cookie = `refresh='';expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=${process.env.REACT_APP_SECRET_DOMAIN};path=/`;
  // document.cookie = `manager='';expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=${process.env.REACT_APP_SECRET_DOMAIN};path=/`;
  // document.cookie = `developer='';expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=${process.env.REACT_APP_SECRET_DOMAIN};path=/`;
  // document.cookie = `PampClient='';expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=${process.env.REACT_APP_SECRET_DOMAIN};path=/`;
  // document.cookie = `PampManager='';expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=${process.env.REACT_APP_SECRET_DOMAIN};path=/`;
  // document.cookie = `ProfessionalEngineer='';expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=${process.env.REACT_APP_SECRET_DOMAIN};path=/`;
  // document.cookie = `Maintenance='';expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=${process.env.REACT_APP_SECRET_DOMAIN};path=/`;
  // document.cookie = `ComplianceOfficer='';expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=${process.env.REACT_APP_SECRET_DOMAIN};path=/`;
}

