import React from "react";
import Button from "@mui/material/Button";
import InfoIcon from "@mui/icons-material/Info";
import { useState } from "react";

import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import ClearIcon from "@mui/icons-material/Clear";
import { Grid } from "@mui/material";
import theme from "../../../../Globals/styling/theme";
export default function ButtonGroups({ user }) {
    const [open, setOpen] = useState(false);


    const close = () => {
        setOpen(false);
    };

    const openModel = () => {
        setOpen(true);
    };

    return (
        <>
            <InfoIcon
                style={{ color: "#13cbc5" }}
                sx={{
                    "&:hover": {
                        color: "white",
                        cursor: "pointer",
                    },
                }}
                onClick={openModel}
            ></InfoIcon>

            <Dialog
                BackdropProps={{ invisible: true }}
                PaperProps={{
                    style: {
                        backgroundColor: "#000",
                        boxShadow: "none",
                        borderWidth: 2,
                        borderRadius: 10,
                        borderColor: "#fff",
                        borderStyle: "solid",
                        backgroundColor: "#101010",
                        color: "#fff",
                    },
                }}
                style={theme.modalStyle.root}
                open={open}
                onClose={close}
            >
                <DialogTitle>
                    <ClearIcon
                        sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            color: "#fff",
                            "&:hover": {
                                color: "red",
                                cursor: "pointer",
                            },
                        }}
                        onClick={close}
                    />
                </DialogTitle>
                <DialogContent>
                    <br /> <br />
                    <Typography
                        sx={{ mb: 1 }}
                        variant="h6"
                        style={{ color: "#fff" }}
                    >
                        Groups assigned to user
                    </Typography>
                    <Grid
                        container
                        gap={2}
                        sx={{ mt: -2, mb: 4, p: 4 }}
                        columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {user.groups.map((temp) => (

                            <Button
                                key={temp.name}
                                sx={theme.mainButton.root}
                                style={{ textTransform: "none" }}
                                variant="outlined"
                                color="primary"
                            >
                                {temp.name}
                            </Button>
                        ))}
                    </Grid>
                </DialogContent>
                <DialogActions></DialogActions>
            </Dialog>
        </>
    );
}
