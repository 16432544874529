import { configureStore } from "@reduxjs/toolkit";
import authenticationReducer from "./Features/Authentication/authSlice";
import { apiSlice } from "./Features/api/apiSlice";
import notificicationsReducer from "./Features/Notifications/notificationsSlice";

function check_if_production() {
  if (process.env.REACT_APP_PRODUCTION === 1) {
    return false;
  }

  else{
    return true;
  }}

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authenticationReducer,
    notifications: notificicationsReducer,
  },

  devTools: check_if_production(),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
});
