import styled from 'styled-components';

// flex smaller 
export const Nav = styled.nav`
  background: #000;
  
  bottom: 0;
  width: 100%;
  justify-content: center;
  alignItems: center;

  display: grid; 
  mind-width: fit-content;
  
  @media (max-width: 560px) {
    bottom: 0;
    width: 100%;
    justify-content: center;
    alignItems: center;
    position: static;

      display: grid; 
      mind-width: fit-content;

}


`;




export const FooterLink = styled.img`
color: #fff;
margin-bottom: 0px;
margin-left: auto;
margin-right: auto;
text-decoration: none;
justify-content: center;
alignItems: center;

&:hover {
	color: #4F4F4F;
	transition: 200ms ease-in;
}
`;
export const FooterName = styled.p`
color: #fff;
margin-bottom: 10px;
text-decoration: none;
justify-content: center;
alignItems: center;

`;


