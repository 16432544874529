import React from "react";
import Button from "@mui/material/Button";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useSelector, useDispatch } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import GroupInfo from "./GroupInfo";
import ClearIcon from "@mui/icons-material/Clear";

import {
    showSuccess,
    showError,
} from "../../../Notifications/notificationsSlice";
import {
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    FormControl,
    Select,
} from "@mui/material";

// import theme from "./theme";
// import { showSuccess, showError } from "../Notifications/notificationsSlice";
// import {
//     useCreateNewEntityMutation,
// } from "../HumanResources/BusinessCompliance/businessComplianceApiSlice";
import Checkbox from "@mui/material/Checkbox";

import TextField from "@mui/material/TextField";
import {
    logout,
    getClient,
    getManager,
} from "../../../Authentication/authSlice";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import theme from "../../../../Globals/styling/theme";
import { useNewUserMutation } from "../../../../Features/api/apiSlice";

export default function ButtonAddUser() {
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const is_client = useSelector(getClient);
    const is_manager = useSelector(getManager);
    const [open, setOpen] = useState(false);

    const [user_email, setUserEmail] = useState("");
    const [user_name, setUserName] = useState("");
    const [user_last_name, setUserLastName] = useState("");

    const [crawl_po, setCrawlPO] = useState(false);
    const [maintenance, setMaintenance] = useState(false);
    const [mechanical_engineer, setMechanicalEngineer] = useState(false);
    const [team_member, setTeamMember] = useState(false);
    const [pamp_client, setPampClient] = useState(false);
    const [pamp_manager, setPampManager] = useState(false);
    const [project_manager, setProjectManager] = useState(false);
    const [compliance_officer, setcompliance_officer] = useState(false);
    const [cell_number, setCell_number] = useState("");
    const [product_owner, setproduct_owner] = useState(false);
    const [preng, setpreng] = useState(false);
    const [internal_external, setInternalExternal] = useState("Internal");
    const internal_external_choices = ["Internal", "External"];

    const [
        addNewUser,
        {
            data: new_user_data,
            isSuccess: isAddNewUserSuccess,
            error: addNewUserError,
            isError: isIsAddNewUserError,
        },
    ] = useNewUserMutation();

    useEffect(() => {
        if (isAddNewUserSuccess) {
            let message = `${new_user_data.success}`;
            dispatch(showSuccess(message));
            setOpen(false);
        }
        if (isIsAddNewUserError) {
            let message = `${addNewUserError.data.error}`;
            dispatch(showError(message));
        }
    }, [isAddNewUserSuccess, isIsAddNewUserError]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = () => {
        setOpen(true);
    };

    const handleOnSubmit = (event) => {
        event.preventDefault();
        const body = {
            email: user_email,
            first_name: user_name,
            last_name: user_last_name,
            cell_number: cell_number,

            maintenance: maintenance,

            team_member: team_member,

            pamp_manager: pamp_manager,

            compliance_officer: compliance_officer,

            pr_eng: preng,
            internal_external: internal_external,
        };

        addNewUser({ body: body });
    };
    const [value, setValue] = useState("");

    const handleChange = (event) => {
        if (event.target.value === "PAMP Manager") {
            setValue("PAMP Manager");
            setPampManager(true);
            setTeamMember(false);
            setMaintenance(false);
        }
        if (event.target.value === "Team Member") {
            setValue("Team Member");
            setPampManager(false);
            setTeamMember(true);
            setMaintenance(false);
        }
        if (event.target.value === "Maintenance") {
            setValue("Maintenance");
            setPampManager(false);
            setTeamMember(false);
            setMaintenance(true);
        }
    };
    return (
        <>
            <Button sx={theme.mainButton.root} onClick={() => handleClick()}>
                Add a User
            </Button>

            <Dialog
                BackdropProps={{ invisible: true }}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        backgroundColor: "#000",
                        boxShadow: "none",
                        borderWidth: 2,
                        borderRadius: 10,
                        borderColor: "#fff",
                        borderStyle: "solid",
                        backgroundColor: "#101010",
                        color: "#fff",
                    },
                }}
                style={theme.modalStyle.root}
            >
                <DialogTitle style={{ color: "#fff" }} variant="h4">
                    <ClearIcon
                        sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            color: "#fff",
                            "&:hover": {
                                color: "red",
                                cursor: "pointer",
                            },
                        }}
                        onClick={handleClose}
                    />
                    Add User
                </DialogTitle>
                <form onSubmit={(e) => handleOnSubmit(e)}>
                    <DialogContent>
                        <TextField
                            onChange={(e) => setUserEmail(e.target.value)}
                            margin="normal"
                            required
                            fullWidth
                            className="inputRounded"
                            id="useremail"
                            label="User Email"
                            name="useremail"
                            autoComplete="useremail"
                            value={user_email || ""}
                            error={!/\S+@\S+\.\S+/.test(user_email)}
                            helperText={
                                !/\S+@\S+\.\S+/.test(user_email)
                                    ? "Please enter a valid email"
                                    : ""
                            }
                        />

                        <TextField
                            onChange={(e) => setUserName(e.target.value)}
                            margin="normal"
                            required
                            fullWidth
                            className="inputRounded"
                            id="username"
                            label="User Name"
                            name="username"
                            autoComplete="username"
                            value={user_name || ""}
                        />

                        <TextField
                            onChange={(e) => setUserLastName(e.target.value)}
                            margin="normal"
                            required
                            fullWidth
                            className="inputRounded"
                            id="userlname"
                            label="User Last Name"
                            name="userlname"
                            autoComplete="userlname"
                            value={user_last_name || ""}
                        />

                        <TextField
                            onChange={(e) => setCell_number(e.target.value)}
                            margin="normal"
                            required
                            fullWidth
                            className="inputRounded"
                            id="cell_number"
                            label="Cell Number"
                            name="cell_number"
                            autoComplete="cell_number"
                            value={cell_number || ""}
                        />
                        <FormControl
                            fullWidth
                            sx={{ mt: 2 }}
                            className="inputRounded"
                        >
                            <InputLabel id="outlined-basic">
                                Internal/External
                            </InputLabel>

                            <Select
                                labelId="type-select-label"
                                id="type-select"
                                value={internal_external}
                                label="Internal/External"
                                onChange={(e) =>
                                    setInternalExternal(e.target.value)
                                }
                                // sx={{ width: '300px'}}
                            >
                                {internal_external_choices.map((item) => (
                                    <MenuItem key={item} value={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Grid container alignItems="center">
                            <Typography
                                style={{ color: "#fff" }}
                                variant="h6"
                                sx={{ mt: "5px", mb: "5px" }}
                            >
                                User Group Selection
                            </Typography>
                            <GroupInfo />
                        </Grid>
                        <Typography
                            style={{ color: "#fff" }}
                            variant="h7"
                            sx={{ mt: "5px", mb: "5px" }}
                        >
                            Main Authorisation *
                        </Typography>
                        <RadioGroup
                            sx={{ mb: 2 }}
                            aria-label="main-group-choices"
                            name="main-group-choices"
                            value={value}
                            onChange={(e) => handleChange(e)}
                        >
                            <FormControlLabel
                                value="PAMP Manager"
                                control={
                                    <Radio
                                        required
                                        color="primary"
                                        checked={pamp_manager}
                                        style={{
                                            color: "#13cbc5",
                                        }}
                                    />
                                }
                                label="PAMP Manager"
                            />
                            <FormControlLabel
                                value="Team Member"
                                checked={team_member}
                                control={
                                    <Radio
                                        required
                                        style={{
                                            color: "#13cbc5",
                                        }}
                                    />
                                }
                                label="Team Member"
                            />
                            <FormControlLabel
                                value="Maintenance"
                                control={
                                    <Radio
                                        required
                                        checked={maintenance}
                                        style={{
                                            color: "#13cbc5",
                                        }}
                                    />
                                }
                                label="Maintenance"
                            />
                        </RadioGroup>
                        <Typography
                            style={{ color: "#fff" }}
                            variant="h7"
                            sx={{ mt: "5px", mb: "5px" }}
                        >
                            Auxilliary Authorisations
                        </Typography>
                        <Typography style={{ color: "#fff", marginTop: 3 }}>
                            Professional Engineer
                            <Checkbox
                                checked={preng}
                                onChange={(e) => setpreng(e.target.checked)}
                                inputProps={{ "aria-label": "controlled" }}
                                style={{
                                    color: "#13cbc5",
                                }}
                            />
                        </Typography>
                        <Typography style={{ color: "#fff" }}>
                            Compliance Officer
                            <Checkbox
                                checked={compliance_officer}
                                onChange={(e) =>
                                    setcompliance_officer(e.target.checked)
                                }
                                inputProps={{ "aria-label": "controlled" }}
                                style={{
                                    color: "#13cbc5",
                                }}
                            />
                        </Typography>
                    </DialogContent>

                    <DialogActions>
                        <Button
                            type="submit"
                            variant="outlined"
                            sx={theme.mainButton.root}
                            align="middle"
                        >
                            Add
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}
