import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { Button, Box, Typography, TextField, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import theme from "./theme";
import {
  useGetUserDetailsQuery,
  useUpdateUserDetailsMutation,
  useGetInternalExternalQuery,
} from "../../Features/api/apiSlice";

export default function PageProfile(props) {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [cell_number, setCell_number] = useState("");

  const [email, setemail] = useState("");

  const {
    data: userData,
    isLoading: userLoading,
    isSuccess: userSuccess,
    isError: userError,
    error: userMessage,
  } = useGetUserDetailsQuery();

  const [
    editUser,
    {
      data: editData,
      isSuccess: iseditUserSuccess,
      error: editUserError,
      isError: iseditUserError,
    },
  ] = useUpdateUserDetailsMutation();

  const handleUpdate = (event) => {
    event.preventDefault();
    const body = {
     
      email: email,
      first_name: name,
      last_name: surname,
      cell_number: cell_number,
      
    };
    editUser({ body: body });
    
  };
  let navigate = useNavigate();

  const handleSubmit = (event) => {};

  const handleBack = (event) => {
    event.preventDefault();
    navigate(-1);
  };

  React.useEffect(() => {
    if (userSuccess) {
      setCell_number(userData.user_details.cell_number)
      setName(userData.user_details.first_name)
      setSurname(userData.user_details.last_name)
      setemail(userData.user_details.email)
  
      };
    
  }, [userSuccess, userLoading, userError]);

  if (userLoading) {
    return <></>;
  } else if (userError) {
    return <></>;
  } else {
    console.log(userData.user_details.groups.length);
    

    return (
      <Container component="main" maxWidth="xs" sx={{ mb: 5 }}>
        <Box sx={{ "& button": { m: 2 } }}>
          <Typography
            style={{ color: "#fff" }}
            gutterBottom
            variant="h3"
            component="h3"
            value
          >
            Profile Details
          </Typography>
          <Box component="form" onSubmit={handleSubmit}>
            <Typography
              style={{ color: "#fff" }}
              gutterBottom
              variant="h5"
              component="h5"
            >
              Personal Details
            </Typography>
            <TextField
              onChange={(e) => setName(e.target.value)}
              //key={props.profilePersonal.id_number}
              //defaultValue={id_number}
              margin="normal"
              className="inputRounded"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              autoComplete="name"
              value={name || ""}
              // error= {!(/^\d{13}$/.test(id_number))}
              // helperText={!(/^\d{13}$/.test(id_number)) ? "ID number must be 13 numerical digits" : ""}
            />

            <TextField
              onChange={(e) => setSurname(e.target.value)}
              //key={props.profilePersonal.id_number}
              //defaultValue={id_number}
              margin="normal"
              className="inputRounded"
              required
              fullWidth
              id="surname"
              label="Surname"
              name="surname"
              autoComplete="surname"
              value={surname || ""}
              // error= {!(/^\d{13}$/.test(id_number))}
              // helperText={!(/^\d{13}$/.test(id_number)) ? "ID number must be 13 numerical digits" : ""}
            />

            <TextField
              onChange={(e) => setemail(e.target.value)}
              //key = {props.profilePersonal.email}
              //defaultValue={props.profilePersonal.email}
              margin="normal"
              className="inputRounded"
              required
              fullWidth
              name="email"
              label="Email"
              id="email"
              autoComplete="email"
              value={email || ""}
            />

            <TextField
              onChange={(e) =>
                setCell_number(e.target.value)
              }
              //key = {props.profilePersonal.email}
              //defaultValue={props.profilePersonal.email}
              margin="normal"
              className="inputRounded"
              required
              fullWidth
              name="cell_number"
              label="Cell Number"
              id="cell_number"
              autoComplete="cell_number"
              value={cell_number || ""}
            />

            <TextField
              //key = {props.profilePersonal.email}
              //defaultValue={props.profilePersonal.email}
              margin="normal"
              className="inputRounded"
              required
              fullWidth
              name="internal_external"
              label="Internal/External User"
              id="internal_external"
              autoComplete="internal_external"
              value={
                userData.user_details.internal_external[0].internal_external ||
                ""
              }
            />

            {userData.user_details.groups.map((temp) => (
              <TextField
                key={temp.name}
                margin="normal"
                className="inputRounded"
                required
                fullWidth
                name="groups"
                label="Allocated Group"
                id="groups"
                autoComplete="groups"
                value={temp.name || ""}
              />
            ))}

            <Container xs={6} align="middle">
            <Button
                sx={theme.mainButton.root}
                onClick={handleUpdate}
                align="middle"
              >
                Update
              </Button>
              <Button
                sx={theme.mainButton.root}
                onClick={handleBack}
                align="middle"
              >
                Back
              </Button>
            </Container>
          </Box>
        </Box>
      </Container>
    );
  }
}
