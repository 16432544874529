import * as React from "react";
import Typography from "@mui/material/Typography";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import { useNavigate } from "react-router-dom";
import { getClient, getManager } from "../Authentication/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { CardActions } from "@mui/material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import BarChartIcon from "@mui/icons-material/BarChart";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import InvertColorsIcon from "@mui/icons-material/InvertColors";
import EngineeringIcon from "@mui/icons-material/Engineering";
import theme from "../../Globals/theme";
import Footer from "../Footer/Footer";
function AppList() {
    let navigate = useNavigate();

    // let CL = `${process.env.REACT_APP_COMPONENTS_URL}`;
    let DEM = `${process.env.REACT_APP_DEM_URL}`;
    let PAMP = `${process.env.REACT_APP_PAMP_URL}`;

    const is_client = useSelector(getClient);
    const is_manager = useSelector(getManager);
    const goToSite = (event) => {
        navigate("/sites/");
    };

    const handleComingSoon = () => {
        navigate("/comingsoon");
    };
    const handleUserPort = () => {
        navigate("/userport/users");
    };
    const handleOnboard = () => {
        navigate("/onboard");
        // console.log(is_manager)
    };

    const handlePAMP = () => {
        window.location.href = `${PAMP}`;
    };

    const handleDEM = () => {
        window.location.href = `${DEM}`;
    };
    // const handleCL = () => {
    //   window.location.href = `${CL}`;
    // };
    const url = "https://physicalaim.com";

    return (
        <>
            <Grid
                container
                spacing={{ xs: 1, sm: 1, md: 1 }}
                columns={{ xs: 2, sm: 4, md: 8 }}
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                {is_manager ? (
                    <Grid item align="center" sx={{ mb: 1 }}>
                        <Card
                            onClick={handleUserPort}
                            sx={{
                                maxWidth: 175,
                                maxHeight: 200,
                                minWidth: 150,
                                minHeight: 300,
                                display: "flex",
                                justiyContent: "space-between",
                                flexDirection: "column",
                                background: "#6C6B6D",
                                color: "#fff",
                                cursor: "pointer",
                                "&:hover": {
                                    color: "#000",
                                    background:
                                        "linear-gradient(45deg, #6C6B6D 30%, #e2e1e2 90%)",
                                    cursor: "pointer",
                                },
                            }}
                            elevation={10}
                        >
                            <CardContent>
                                <Typography
                                    align="center"
                                    variant="h6"
                                    sx={{ mb: -1.5 }}
                                >
                                    UserPort
                                </Typography>
                                <br />
                                <AdminPanelSettingsIcon
                                    sx={{ fontSize: 40, mb: -2, color: "#fff" }}
                                />
                                <Typography variant="body2">
                                    <br />
                                    Add new users and assign roles
                                </Typography>
                                <br />
                            </CardContent>
                            <CardActions></CardActions>
                        </Card>
                    </Grid>
                ) : (
                    <></>
                )}

                {is_manager ? (
                    <Grid item align="center" sx={{ mb: 1 }}>
                        <Card
                            onClick={handleOnboard}
                            sx={{
                                maxWidth: 175,
                                maxHeight: 200,
                                minWidth: 150,
                                minHeight: 300,
                                display: "flex",
                                justiyContent: "space-between",
                                flexDirection: "column",
                                background: "#6C6B6D",
                                color: "#fff",
                                cursor: "pointer",
                                "&:hover": {
                                    color: "#000",
                                    background:
                                        "linear-gradient(45deg, #6C6B6D 30%, #e2e1e2 90%)",
                                    cursor: "pointer",
                                },
                            }}
                            elevation={10}
                        >
                            <CardContent>
                                <Typography
                                    align="center"
                                    variant="h6"
                                    sx={{ mb: -1.5 }}
                                >
                                    Onboarding and Access
                                </Typography>
                                <br />
                                <PersonAddIcon
                                    sx={{ fontSize: 40, mb: -2, color: "#fff" }}
                                />
                                <Typography variant="body2">
                                    <br />
                                    Manage employee documents and onboard new
                                    employees to projects
                                </Typography>
                                <br />
                            </CardContent>
                            <CardActions></CardActions>
                        </Card>
                    </Grid>
                ) : (
                    <Grid item align="center" sx={{ mb: 1 }}>
                        <Card
                            onClick={handleOnboard}
                            sx={{
                                maxWidth: 175,
                                maxHeight: 200,
                                minWidth: 150,
                                minHeight: 300,
                                display: "flex",
                                justiyContent: "space-between",
                                flexDirection: "column",
                                background: "#6C6B6D",
                                color: "#fff",
                                cursor: "pointer",
                                "&:hover": {
                                    color: "#000",
                                    background:
                                        "linear-gradient(45deg, #6C6B6D 30%, #e2e1e2 90%)",
                                    cursor: "pointer",
                                },
                            }}
                            elevation={10}
                        >
                            <CardContent>
                                <Typography
                                    align="center"
                                    variant="h6"
                                    sx={{ mb: -1.5 }}
                                >
                                    Onboard
                                </Typography>
                                <br />
                                <br />
                                <AddToPhotosIcon
                                    sx={{ fontSize: 40, mb: -2, color: "#fff" }}
                                />
                                <Typography variant="body2">
                                    <br />
                                    Upload required documents for project
                                    onboarding
                                </Typography>
                                <br />
                            </CardContent>
                            <CardActions></CardActions>
                        </Card>
                    </Grid>
                )}

                {is_manager ? (
                    <Grid item align="center" sx={{ mb: 1 }}>
                        <Card
                            onClick={handleDEM}
                            sx={{
                                maxWidth: 175,
                                maxHeight: 200,
                                minWidth: 150,
                                minHeight: 300,
                                display: "flex",
                                justiyContent: "space-between",
                                flexDirection: "column",
                                background: "#6C6B6D",
                                color: "#fff",
                                cursor: "pointer",
                                "&:hover": {
                                    color: "#000",
                                    background:
                                        "linear-gradient(45deg, #6C6B6D 30%, #e2e1e2 90%)",
                                    cursor: "pointer",
                                },
                            }}
                            elevation={10}
                        >
                            <CardContent>
                                <Typography
                                    align="center"
                                    variant="h6"
                                    sx={{ mb: -1.5 }}
                                >
                                    Digital Engineering
                                </Typography>
                                <br />
                                <ConfirmationNumberIcon
                                    sx={{ fontSize: 40, mb: -2, color: "#fff" }}
                                />
                                <Typography variant="body2">
                                    <br />
                                    Manage app development, operation and
                                    innovation.
                                </Typography>
                                <br />
                            </CardContent>
                            <CardActions></CardActions>
                        </Card>
                    </Grid>
                ) : (
                    <></>
                )}

                <Grid item align="center" sx={{ mb: 1 }}>
                    <a
                        style={{ "text-decoration": "none" }}
                        target="_blank"
                        href={url}
                    >
                        <Card
                            onClick={url}
                            sx={{
                                maxWidth: 175,
                                maxHeight: 200,
                                minWidth: 150,
                                minHeight: 300,
                                display: "flex",
                                justiyContent: "space-between",
                                flexDirection: "column",
                                background: "#6C6B6D",
                                color: "#fff",
                                cursor: "pointer",
                                "&:hover": {
                                    color: "#000",
                                    background:
                                        "linear-gradient(45deg, #6C6B6D 30%, #e2e1e2 90%)",
                                    cursor: "pointer",
                                },
                            }}
                            elevation={10}
                        >
                            <CardContent>
                                <Typography
                                    align="center"
                                    variant="h6"
                                    sx={{ mb: -1.5 }}
                                >
                                    PAMP 2.0
                                </Typography>
                                <br />
                                <EngineeringIcon
                                    sx={{ fontSize: 40, mt: 3, color: "#fff" }}
                                />
                                <Typography variant="body2">
                                    <br />
                                    Physical Asset Management Program version
                                    2.0
                                </Typography>
                                <br />
                            </CardContent>
                            <CardActions></CardActions>
                        </Card>
                    </a>
                </Grid>

                <Grid item align="center" sx={{ mb: 1 }}>
                    <Card
                        onClick={handlePAMP}
                        sx={{
                            maxWidth: 175,
                            maxHeight: 200,
                            minWidth: 150,
                            minHeight: 300,
                            display: "flex",
                            justiyContent: "space-between",
                            flexDirection: "column",
                            background: "#6C6B6D",
                            color: "#fff",
                            cursor: "pointer",
                            "&:hover": {
                                color: "#000",
                                background:
                                    "linear-gradient(45deg, #6C6B6D 30%, #e2e1e2 90%)",
                                cursor: "pointer",
                            },
                        }}
                        elevation={10}
                    >
                        <CardContent>
                            <Typography
                                align="center"
                                variant="h6"
                                sx={{ mb: -1.5 }}
                            >
                                PAMP 3.0
                                <br />
                            </Typography>
                            <br />
                            <EngineeringIcon
                                sx={{ fontSize: 40, mt: 3, color: "#fff" }}
                            />
                            <Typography variant="body2">
                                <br />
                                Physical Asset Management Program version 3.0
                            </Typography>
                            <br />
                        </CardContent>
                        <CardActions></CardActions>
                    </Card>
                </Grid>
            </Grid>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <Footer />
        </>
    );
}

export default AppList;
