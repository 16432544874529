import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import LogoutIcon from "@mui/icons-material/Logout";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import AppsIcon from "@mui/icons-material/Apps";
import GroupsIcon from "@mui/icons-material/Groups";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../Features/Authentication/authSlice";
import custom_theme from "../styling/theme"
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import EngineeringIcon from '@mui/icons-material/Engineering';
import HomeIcon from '@mui/icons-material/Home';
import Creng from "../../Globals/components/Images/CRENG_LOGO_DARK.png"
import { getClient, getManager } from "../../Features/Authentication/authSlice";

import { useSelector } from "react-redux"
const drawerWidth = 260;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function ResponsiveAppBar() {
  let navigate = useNavigate();
  const is_manager = useSelector(getManager);

  const dispatch = useDispatch();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [anchorElProfile, setanchorElProfile] = React.useState(null);
  const [anchorElLogout, setanchorElLogout] = React.useState(null);

  const handleUserPortNav = () => {
    window.location.href = `${process.env.REACT_APP_USERPORT_URL}/userport/users`;
  };

  const handleDEMNav = () => {
    window.location.href = `${process.env.REACT_APP_DEM_URL}`;
  };

  const handlePAMP = () => {
    window.location.href = `${process.env.REACT_APP_PAMP_URL}`;
  };

  const handleOnboardNav = () => {
    window.location.href = `${process.env.REACT_APP_USERPORT_URL}/onboard`;
  };

  const handleHome = () => {
    window.location.href = `${process.env.REACT_APP_USERPORT_URL}`;
  };
  const handleMenuLogout = (event) => {
    setanchorElLogout(event.currentTarget);
  };

  const handleCloseLogout = () => {
    setanchorElLogout(null);
  };
  const handlePrevPage = () => {
    navigate(-1);
  };
  const handleLogout = () => {
    setanchorElLogout(null);
    dispatch(logout());
  };

  const handleMenuProfile = (event) => {
    setanchorElProfile(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setanchorElProfile(null);
  };

  const handleProfile = () => {
    // setanchorElProfile(null);
    let user = `${process.env.REACT_APP_USERPORT_URL}`;
    window.location.href = `${user}/profile`;
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ flexGrow: 1, mb: 0 }}>
      <AppBar style={custom_theme.appBarStyle.root}>
        <Toolbar>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
            {/* Back Button */}
          {/* <IconButton size="large" color="inherit" onClick={handlePrevPage}>
            <ArrowBackIcon />
          </IconButton> */}
          <Typography variant="h6" component="div" sx={{ flexGrow: 12 }} textAlign="center">
            CrEng
            <Typography variant="h6" component="div" sx={{ flexGrow: 12, fontSize: 17 }} textAlign="center">
              BusiOpsMan
                          </Typography>
          </Typography>

          {/* Logout button */}
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar-right"
              aria-haspopup="true"
              color="inherit"
              onClick={handleMenuLogout}
            >
              <AccountCircleIcon sx={{ color: "#6c6b6d"}}/>
            </IconButton>
            <Menu
              id="menu-appbar-right"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElLogout)}
              onClose={handleCloseLogout}
            >
                            <MenuItem onClick={handleProfile}>Profile</MenuItem>

              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
       sx={{
        // backgroundColor: "#000",
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          backgroundColor: "#000",
          color: "#fff",
        },

      }}
        // variant="persistent"
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
      >
        <DrawerHeader>
        <img src={Creng} width="150px" margin-left="auto" margin-right="auto"/>

          <IconButton style={{color:"#fff"}} onClick={handleDrawerClose}>{theme.direction === "ltr" ? <ChevronLeftIcon /> : <ChevronRightIcon />}</IconButton>
        </DrawerHeader>
        <Divider sx={{
            color: "#13cbc5",
            "&::before, &::after": {
              borderColor: "#13cbc5",
            },
          }}>Navigation</Divider>

        <List>
          <Link style={custom_theme.palette.button_page_link.main} to={`/`}>
            <ListItem onClick={handleHome} button key="Home">
                <ListItemIcon style={{ color: "#13cbc5" }}>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
          </Link>

          <Divider sx={{
            color: "#13cbc5", mt: 3, mb: 1,
            "&::before, &::after": {
              borderColor: "#13cbc5",
            },
          }} >BusiOpsMan Apps</Divider>

          {is_manager?
          <>
          <ListItem onClick={handleUserPortNav} button key="User Portal">
              <ListItemIcon style={{ color: "#13cbc5" }}>
              <AppsIcon />
            </ListItemIcon>
            <ListItemText primary="User Portal" />
          </ListItem>
          <ListItem onClick={handleDEMNav} button key="Digital Engineering">
              <ListItemIcon style={{ color: "#13cbc5" }}>
              <ConfirmationNumberIcon />
            </ListItemIcon>
            <ListItemText primary="Digital Engineering" />
          </ListItem> </>:<></>}
          <ListItem onClick={handleOnboardNav} button key="Onboard">
              <ListItemIcon style={{ color: "#13cbc5" }}>
              <AppsIcon />
            </ListItemIcon>
            <ListItemText primary="Onboard" />
          </ListItem>
          <ListItem onClick={handlePAMP} button key="PAMP">
              <ListItemIcon style={{ color: "#13cbc5" }}>
              <EngineeringIcon />
            </ListItemIcon>
            <ListItemText primary="PAMP" />
          </ListItem>
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
      </Main>
    </Box>
  );
}
