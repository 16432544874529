import * as React from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import { useNavigate } from "react-router-dom";
import { useGetUserListQuery } from "../../../../api/apiSlice";

import TableUserDocuments from "./TableUserDoc";
//     getModalOpen,  setModalOpen, 
//   } from "./siteSlice";
import Footer from "../../../../Footer/Footer";
export default function LandingTableManagerDocuments() {
  let navigate = useNavigate();

  const
  {
  data: userData,
  isLoading: userLoading,
  isSuccess: userSuccess,
  isError: userError,
  error: userMessage,
} = useGetUserListQuery();


if (userLoading) {
  return (
      <div className="center-screen">
          {/* <LoadingSpinner /> */}
      </div>
  );
} else if (userError ) {
  return (
      <div className="center-screen">
          {/* <Error500 /> */}
      </div>
  );
} else {
  return (
    <>

   <TableUserDocuments  user_data={userData.data}/>
   <Footer/>
    </>
  );
}}
